<template>
  <v-snackbar
    v-model="snackbar"
    @click="snackbar = false"
    :timeout="duration"
    :color="color"
    top>
   <div class="d-flex">
     <v-icon class="mr-4">{{icon}}</v-icon>
     <div class="font-weight-bold mt-1" v-html="text" />
   </div>
    <v-btn class="mt-6" v-if="color === 'red'" @click="snackbar = false" light color="#F5F5F5">Close</v-btn>
  </v-snackbar>
</template>
<script>
import event, { Events } from '../event'
export default {
  name: 'Alert',
  data () {
    return {
      snackbar: false,
      color: this.$vuetify.theme.currentTheme.accent,
      duration: 5000,
      text: null,
      cb: null,
      icon: ''
    }
  },
  methods: {
    displayMessage (message, style, duration) {
      this.text = message
      if (duration) {
        this.duration = duration
      }

      if (style) {
        this.color = style
      }

      if (this.color === 'green') {
        this.icon = 'fas fa-check'
      } else if (this.color === 'red') {
        this.icon = ''
      } else {
        this.icon = 'fas fa-info'
      }

      this.snackbar = true
    }
  },
  mounted () {
    /**
     * Alert Event Listener
     */
    event.on(Events.ALERT, (message, style, duration) => {
      this.displayMessage(message, style, duration)
    })

    event.on(Events.SUCCESS, (message, duration) => {
      this.displayMessage(message, 'green', duration)
    })

    event.on(Events.ERROR, (message, duration) => {
      this.displayMessage(message, 'red', 0)
    })
  }
}
</script>
