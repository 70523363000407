<template>
  <div id="confirm">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text class="mt-5 mb-1">{{ contentHtml }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="respond(1)">{{ ok }}</v-btn>
          <v-btn color="secondary" text @click="respond(0)">{{ cancel }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import event, { Events } from '../event'
export default {
  name: 'Confirm',
  data () {
    return {
      dialog: false,
      callbackEvent: 'confirm-response',
      title: '',
      contentHtml: '',
      ok: 'Delete',
      cancel: 'Cancel'
    }
  },

  methods: {
    respond (response) {
      this.dialog = false

      if (typeof this.callbackEvent === 'function' && response) {
        return this.callbackEvent()
      }

      if (typeof this.callbackEvent === 'string') {
        event.emit(this.callbackEvent, response)
      }
    }
  },

  mounted () {
    event.on(Events.CONFIRM, (message, cbEvent, params) => {
      if (cbEvent) {
        this.callbackEvent = cbEvent
      }

      if (params) {
        this.ok = params.ok || 'Delete'
        this.cancel = params.cancel || 'Cancel'
        this.title = params.title || ''
      }

      this.contentHtml = message
      this.dialog = true
    })
  }
}
</script>
