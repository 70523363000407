<template>
  <v-menu
    ref="dateRangeMenu"
    v-model="dateRangeMenu"
    :close-on-content-click="false"
    :return-value.sync="value"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        prepend-inner-icon="fa-calendar"
        readonly outlined
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="value"
      range
      no-title
      scrollable
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="dateRangeMenu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="okButton(value)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { DateTime } from 'luxon'
export default {
  name: 'DateRangePicker',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    value: [],
    dateRangeMenu: false
  }),
  computed: {
    dateRangeText () {
      const start = DateTime.fromISO(this.value[0]).toLocaleString()
      const end = DateTime.fromISO(this.value[1]).toLocaleString()
      return `${start} : ${end}`
    }
  },
  watch: {
    value (newValue) {
      this.$emit('input', newValue)
    },
    $attrs: {
      deep: true,
      handler: function (value) {
        this.value = value.value
      }
    }
  },
  methods: {
    okButton (value) {
      this.$refs.dateRangeMenu.save(value)
      this.$emit('manual')
    }
  }
}
</script>
