<template>
  <v-form ref="privacyPracticesForm" v-model="privacyPracticesForm">
    <h2 class="mb-2">Authorized Contacts</h2>
    <div class="font-italic">Who may we speak to regarding your medical care?</div>

    <h3 class="my-6" @click="addAuthorizedContact" style="cursor: pointer;">
      <v-icon class="text-h4" style="color: #2BD9EB;">
        mdi-plus-circle
      </v-icon>
      Add Contact
    </h3>
    <div class="mb-8">
      <v-row v-for="(contact, index) in privacyPracticesInfo.authorizedContacts" :key="index">
        <v-col cols="11">
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="privacyPracticesInfo.authorizedContacts[index].name"
                label="* Contact Full Name"
                filled dense
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="privacyPracticesInfo.authorizedContacts[index].relationship"
                label="* Relationship"
                filled dense
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="privacyPracticesInfo.authorizedContacts[index].main_phone"
                label="* Contact Phone"
                filled dense
                v-mask="'(###) ###-####'"
                :rules="[rules.required, rules.phone]"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex align-self-center justify-center pa-0">
          <v-icon class="text-h4 red--text" @click="deleteAuthorizedContact(index)">mdi-alpha-x-box</v-icon>
        </v-col>
      </v-row>
    </div>

    <h2 class="mt-12">Personal Representative for Minors or Other Needs</h2>
    <v-checkbox label="I have a personal representative" v-model="privacyPracticesInfo.haveRepresentative" class="my-1" @change="checkRepresentative"/>
    <v-row dense v-if="privacyPracticesInfo.haveRepresentative">
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="privacyPracticesInfo.personalRepresentative.name"
          label="* Representative Name"
          filled dense
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="privacyPracticesInfo.personalRepresentative.relationship"
          label="* Representative Relationship"
          filled dense
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="privacyPracticesInfo.personalRepresentative.main_phone"
          label="* Representative Phone"
          filled dense
          v-mask="'(###) ###-####'"
          :rules="[rules.required, rules.phone]"
        />
      </v-col>
    </v-row>

    <h2 class="mt-12 mb-2">Acknowledgement of Receipt of Privacy Practices</h2>
<!--    TODO: update with privacy practices -->
    <a href="/privacy_practices.pdf" target="_blank" >View Privacy Practices</a>
    <v-checkbox
      v-model="privacyPracticesInfo.certify_privacy_practices"
      label="I acknowledge that I have an understanding of the Notice of Privacy Practices from Northstar Healthcare"
      :rules="[rules.required]"
      :disabled="privacyPracticesInfo.certify_privacy_practices && !!privacyPracticesInfo.signature.signature"
    />

    <SignatureBlock v-model="privacyPracticesInfo.signature" :certified="privacyPracticesInfo.certify_privacy_practices"/>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validatePrivacyPracticesForm"
    />
  </v-form>
</template>
<script>
import RulesMixin from '../../mixins/RulesMixin'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapActions, mapState } from 'vuex'
import SignatureBlock from '@/components/PatientIntake/SignatureBlock'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'PrivacyPracticesForm',
  mixins: [RulesMixin],
  components: { FormNavButtons, SignatureBlock },
  data () {
    return {
      IntakeSteps,
      privacyPracticesForm: true
    }
  },
  computed: {
    ...mapState('PatientIntake', ['privacyPracticesInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    validatePrivacyPracticesForm () {
      this.$refs.privacyPracticesForm.validate()
      if (this.privacyPracticesForm && this.privacyPracticesInfo.signature.signature) {
        this.setInfo({ label: IntakeSteps.PRIVACY.label, data: this.privacyPracticesInfo })
        this.$emit('nextStep', IntakeSteps.PRIVACY.form)
      }
    },
    addAuthorizedContact () {
      if (!this.privacyPracticesInfo.authorizedContacts) {
        this.privacyPracticesInfo.authorizedContacts = [{ name: '', relationship: '', main_phone: '' }]
      } else {
        this.privacyPracticesInfo.authorizedContacts.push({ name: '', relationship: '', main_phone: '' })
      }
    },
    deleteAuthorizedContact (index) {
      this.privacyPracticesInfo.authorizedContacts.splice(index, 1)
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.PRIVACY.label, data: this.privacyPracticesInfo })
      this.$emit(command)
    },
    checkRepresentative () {
      if (this.privacyPracticesInfo.haveRepresentative) {
        return
      }
      this.privacyPracticesInfo.personalRepresentative.name = ''
      this.privacyPracticesInfo.personalRepresentative.relationship = ''
      this.privacyPracticesInfo.personalRepresentative.main_phone = ''
    }
  }
}
</script>

<style>

</style>
