import QueryString from '@/library/QueryString'

export const ORGANIZATION_TYPES = {
  LAW_FIRM: 'attorney',
  CLINIC: 'healthcare_provider',
  INDIVIDUAL: 'individual'
}

export default {
  namespaced: true,
  state: {
    id: null,
    type: '',
    name: null,
    // locations are clinic locations
    locations: [],
    // service locations are referral (polaris) locations
    serviceLocations: [],
    users: [],
    invites: []
  },
  getters: {
    apiUrl (state) {
      return `/v1/organization/${state.id}`
    },
    locations (state) {
      return state.locations
    },
    serviceLocations (state) {
      return state.serviceLocations
    },
    isLawFirm (state) {
      return state.type === ORGANIZATION_TYPES.LAW_FIRM
    },
    isHealthClinic (state) {
      return state.type === ORGANIZATION_TYPES.CLINIC
    },
    isIndividual (state) {
      return state.type === ORGANIZATION_TYPES.INDIVIDUAL
    },
    usesDashboard (state) {
      return state.type === ORGANIZATION_TYPES.CLINIC || state.type === ORGANIZATION_TYPES.INDIVIDUAL || state.type === ORGANIZATION_TYPES.CLINIC_STAFF
    },
    defaultLandingPage (state) {
      if (state.type === ORGANIZATION_TYPES.CLINIC) {
        return 'Dashboard'
      } else if (state.type === ORGANIZATION_TYPES.INDIVIDUAL) {
        return 'PatientIntake'
      } else {
        return 'AttorneyCases'
      }
    }
  },
  mutations: {
    setOrganizationName (state, name) {
      state.name = name
    },
    setOrganization (state, organization) {
      state.id = organization.id
      state.name = organization.name
      state.type = organization.type.type
    },
    setLocations (state, locations) {
      state.locations = locations
    },
    setServiceLocations (state, locations) {
      state.serviceLocations = locations
    },
    setUsers (state, users) {
      state.users = users
    },
    setInvites (state, invites) {
      state.invites = invites
    }
  },
  actions: {
    updateOrganization (ctx, organization) {
      ctx.commit('User/setCurrentOrganization', organization, { root: true })
      ctx.commit('setOrganization', organization)
    },
    async getOrganization (ctx, organizationId) {
      const response = await window.axios.get('/v1/organization/' + organizationId + '?clinicLocations=true')
      return response.data.payload
    },
    async getCurrentLocation (ctx, locationId) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/locations/' + locationId)
      return response.data.payload
    },
    async getLocations ({ state, getters, commit }) {
      const response = await window.axios.get(getters.apiUrl + '/locations', {
        params: {
          locations: 'clinic'
        }
      })
      commit('setLocations', response.data.payload)
    },
    async getServiceLocations ({ state, getters, commit }) {
      const response = await window.axios.get(getters.apiUrl + '/locations', {
        params: {
          locations: 'service'
        }
      })
      commit('setServiceLocations', response.data.payload)
    },
    async deleteLocation (ctx, payload) {
      await window.axios.delete(ctx.getters.apiUrl + `/locations/${payload.locationId}`)
      let type = 'locations'
      let action = 'setLocations'
      if (payload.type === 'service') {
        type = 'serviceLocations'
        action = 'setServiceLocations'
      }
      const locationIndex = ctx.state[type].findIndex(l => l.id === payload.locationId)
      const locations = [...ctx.state[type]]
      locations.splice(locationIndex, 1)
      if (locationIndex) {
        ctx.commit(action, locations)
      }
    },
    async getUsers ({ commit, getters }) {
      const response = await window.axios.get(getters.apiUrl + '/users')
      commit('setUsers', response.data.payload.users)
      commit('setInvites', response.data.payload.invites)
    },
    async getPolarisReferrals (ctx, params) {
      let queryString = ''
      if (params) {
        queryString = QueryString.format(params)
      }
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/polaris-referrals' + encodeURI(queryString))
      return response.data.payload
    }
  }
}
