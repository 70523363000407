import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import AdminRoutes from './adminRoutes'
import PrivateRoutes from './privateRoutes'
import PublicRoutes from './publicRoutes'
import TokenRoutes from './tokenRoutes'

Vue.use(VueRouter)

const routes = [
  ...AdminRoutes,
  ...PublicRoutes,
  ...PrivateRoutes,
  ...TokenRoutes,
  {
    path: '/*',
    beforeEnter: (to, from, next) => {
      // wildcard route to redirect unknown paths to login or dashboard
      if (store.getters['User/isLoggedIn']) {
        next({ name: 'Dashboard' })
      } else {
        next({ name: 'Login' })
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // collapse all matched route middleware into a single middleware array
  const middleware = to.matched.reduce((middleware, currentRoute) => {
    if (currentRoute.meta.middleware && currentRoute.meta.middleware.length > 0) {
      middleware = [...middleware, ...currentRoute.meta.middleware]
    }

    return middleware
  }, [])

  // execute each matched middleware
  if (middleware.length > 0) {
    middleware.forEach(middleware => {
      middleware(to, from, next)
    })
  } else {
    return next()
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Telescope'
  })
})

router.onError(error => {
  router.push({ name: 'Error', params: { error: error.message } })
})

export default router
