import store from '../store'

export default [
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue'),
    meta: {
      title: 'Telescope | Register',
      layout: 'public'
    }
  },
  {
    path: '/signup/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue'),
    meta: {
      title: 'Telescope | Sign Up',
      layout: 'public'
    }
  },
  {
    path: '/signup/individual/verify',
    name: 'PatientInviteVerification',
    component: () => import(/* webpackChunkName: "PatientRegister" */ '../views/PatientInviteVerification.vue'),
    meta: {
      title: 'Telescope | Verification',
      layout: 'public'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    meta: {
      title: 'Telescope | Login',
      layout: 'public'
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['User/isLoggedIn']) {
        next({ name: 'Dashboard' })
      } else {
        next()
      }
    }
  },
  {
    path: '/:organizationId/request/appointment',
    name: 'NewAppointmentRequest',
    props: true,
    component: () => import(/* webpackChunkName: "NewAppointmentRequest" */ '../views/NewAppointmentRequest.vue'),
    meta: {
      title: 'Telescope | New Appointment Request',
      layout: 'public'
    }
  },
  {
    path: '/error',
    name: 'Error',
    props: true,
    component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue'),
    meta: {
      title: 'Error'
    }
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "PasswordReset" */ '../views/PasswordReset.vue'),
    meta: {
      title: 'Telescope | Password Reset',
      layout: 'public'
    }
  }
]
