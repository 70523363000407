import { DateTime } from 'luxon'

class MetricsController {
  value

  startDate = 0

  endDate = 0

  locations = []

  canGroupByLocation = true
  groupByLocations = false

  setStartDate (date) {
    this.startDate = date
  }

  setEndDate (date) {
    this.endDate = date
  }

  setLocations (locations) {
    this.locations = locations
  }

  processTimeSeriesResponse (response) {
    this.value = response.data.payload.data
    this.value.labels = response.data.payload.data.labels.map(d => {
      return DateTime.fromISO(d).toFormat('L/dd')
    })
  }

  formatQueryParams () {
    let queryString = `?start=${this.startDate}&end=${this.endDate}`

    if (this.locations.length > 0) {
      queryString += `&locations=${this.locations}`
    }

    if (this.groupByLocations) {
      queryString += '&groupByLocations=true'
    }

    return queryString
  }

  getValue () {
    return this.value
  }
}

export default MetricsController
