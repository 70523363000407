<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <date-range-picker label="date range" @manual="datePreset = 'custom'" v-model="filters.dateFilter" />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-select outlined v-model="datePreset" :items="datePresetsOptions"/>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-select v-if="locations.length > 0" v-model="filters.locationFilter"
                  :items="$store.getters['Organization/locations']"
                  item-text="name" item-value="id"
                  multiple
                  label="locations" outlined>
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleLocations">
              <v-list-item-action>
                <v-simple-checkbox @click="toggleLocations" :value="allLocations" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4" xl="3" v-for="(metric, index) in metrics" :key="index">
        <dashboard-component :metric="metric" @refresh="fetchMetricData(metric)" />
      </v-col>
    </v-row>

    <v-speed-dial v-model="speedDial" bottom right direction="top" transition="slide-y-reverse-transition" absolute
                  open-on-hover>
      <template #activator>
        <v-btn v-model="speedDial" color="accent" fab>
          <v-icon v-if="speedDial">fa-times</v-icon>
          <v-icon v-else>fa-plus</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{on}">
          <v-btn fab dark small v-on="on" :to="{name: 'CreateCase' }">
            <v-icon small>fas fa-briefcase-medical</v-icon>
          </v-btn>
        </template>
        <span>create new patient</span>
      </v-tooltip>

    </v-speed-dial>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import DateRangePicker from '@/components/FormFields/DateRangePicker'
import DashboardComponent from '@/components/ClinicAdminDashboard/DashboardComponent'
import AvgBilledPerCase from '@/library/metrics/AvgBilledPerCase'
import AvgTimeBilledToCollected from '@/library/metrics/AvgTimeBilledToCollected'
import AvgReferralsIn from '@/library/metrics/AvgReferralsIn'
import AvgCompletedPtEvals from '@/library/metrics/AvgCompletedPtEvals'
import NewCases from '@/library/metrics/NewCases'
import Vue from 'vue'
import AvgNumberVisits from '@/library/metrics/AvgNumberVisits'

export default {
  name: 'ClinicAdminDashboard',
  components: {
    DateRangePicker,
    DashboardComponent
  },
  data: () => ({
    speedDial: false,
    dateRangeMenu: false,
    filters: {
      dateFilter: [],
      locationFilter: []
    },
    datePreset: 'mtd',
    datePresetsOptions: [
      { text: 'week' },
      { text: 'mtd' },
      { text: 'ytd' },
      { text: '3 months' },
      { text: '6 months' },
      { text: '12 months' },
      { text: 'custom' }
    ],
    metrics: [
      {
        title: 'New Cases',
        component: 'DigitMetric',
        key: 'new_cases',
        controller: NewCases,
        loading: false,
        props: {
          suffix: 'cases',
          data: 0
        }
      },
      {
        title: 'Avg. Referrals In',
        component: 'LineChartMetric',
        key: 'avg_referrals_in',
        controller: AvgReferralsIn,
        loading: false,
        props: {
          data: {}
        }
      },
      {
        title: 'Avg. Completed PT Evals',
        component: 'LineChartMetric',
        key: 'avg_completed_pt_evals',
        controller: AvgCompletedPtEvals,
        loading: false,
        props: {
          data: {}
        }
      },
      {
        title: 'Avg. Number Visits',
        component: 'LineChartMetric',
        key: 'avg_number_visits',
        controller: AvgNumberVisits,
        loading: false,
        props: {
          data: {}
        }
      },
      {
        title: 'Avg. Billed / Case',
        component: 'DigitMetric',
        key: 'avg_billed_per_case',
        controller: AvgBilledPerCase,
        loading: false,
        props: {
          prefix: '$',
          data: 0,
          animateStep: 100
        }
      },
      {
        title: 'Avg. Time Bill to Collection',
        component: 'DigitMetric',
        key: 'avg_time_billed_to_collected',
        controller: AvgTimeBilledToCollected,
        loading: false,
        props: {
          suffix: 'days',
          data: 0
        }
      }
    ]
  }),
  computed: {
    ...mapGetters({
      locations: 'Organization/locations'
    }),
    allLocations () {
      return this.filters.locationFilter.length === this.locations.length
    },
    someLocations () {
      return this.filters.locationFilter.length > 0 && !this.allLocations
    }
  },
  watch: {
    'filters.locationFilter': function () {
      this.fetchData()
    },
    'filters.dateFilter': function (newVal) {
      if (newVal.length === 2) {
        this.fetchData()
      }
    },
    datePreset () {
      this.calculatePreset()
      this.fetchData()
    }
  },
  mounted () {
    this.$store.dispatch('Organization/getLocations')
    this.calculatePreset()
  },
  methods: {
    fetchData () {
      this.metrics.forEach(async (metric) => {
        metric.controller.setStartDate(this.filters.dateFilter[0])
        metric.controller.setEndDate(this.filters.dateFilter[1])
        metric.controller.setLocations(this.filters.locationFilter)

        this.fetchMetricData(metric)
      })
    },
    fetchMetricData (metric) {
      metric.loading = true

      metric.controller.getData()
        .then(r => {
          metric.props.data = r
        })
        .finally(() => {
          metric.loading = false
        })
    },
    calculatePreset () {
      let endDate = DateTime.now()
      let startDate = endDate.minus({ weeks: 1 })

      if (this.filters.dateFilter.length === 2) {
        endDate = DateTime.fromISO(this.filters.dateFilter[1])
      }

      switch (this.datePreset) {
        default:
        case 'week':
          startDate = endDate.minus({ weeks: 1 })
          break
        case 'mtd':
          startDate = endDate.startOf('month')
          break
        case 'ytd':
          startDate = endDate.startOf('year')
          break
        case '3 months':
          startDate = endDate.minus({ months: 3 })
          break
        case '6 months':
          startDate = endDate.minus({ months: 6 })
          break
        case '12 months':
          startDate = endDate.minus({ months: 12 })
          break
        case 'custom':
          return
      }

      Vue.set(this.filters.dateFilter, 0, startDate.toISODate())
      Vue.set(this.filters.dateFilter, 1, endDate.toISODate())
    },
    toggleLocations () {
      this.$nextTick(() => {
        if (this.allLocations) {
          this.filters.locationFilter = []
        } else {
          this.filters.locationFilter = this.locations.map(l => l.id)
        }
      })
    }
  }
}
</script>
