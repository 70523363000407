<template>
  <div>
    <p>patient dashboard information goes here</p>
  </div>
</template>
<script>

export default {
  name: 'IndividualDashboard',
  components: {},
  data: () => ({
  }),
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>
