<template>
  <v-card class="metric">
    <v-card-title class="d-flex justify-space-between">
      {{ metric.title }}
      <v-menu>
        <template #activator="{ on }">
          <v-btn icon v-on="on"><v-icon small>fa-ellipsis-v</v-icon></v-btn>
        </template>

        <v-list>
          <v-list-item @click="toggleCompareLocations(metric)">
            <v-list-item-action>
              <v-checkbox :value="metric.controller.groupByLocations" />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Compare Locations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-responsive :aspect-ratio="16 / 9" class="pb-4 px-2">
      <component v-if="!metric.loading" :is="metric.component" :id="metric.key" v-bind="metric.props"/>
      <div v-else class="text-center mb-5 mt-5">
        <v-icon x-large>fa fa-cog fa-spin</v-icon>
      </div>
    </v-responsive>
  </v-card>
</template>
<script>
import DigitMetric from './DigitMetric'
import LineChartMetric from './LineChartMetric'

export default {
  name: 'DashboardComponent',
  components: {
    DigitMetric,
    LineChartMetric
  },
  props: {
    metric: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleCompareLocations (metric) {
      metric.controller.groupByLocations = !metric.controller.groupByLocations
      this.$emit('refresh')
    }
  }
}
</script>
