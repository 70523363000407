<template>
  <v-form ref="medicationsForm" v-model="medicationsForm" >
    <h2 class="mb-2">Medications</h2>
    <div class="font-italic">
      Please list all medications that you are currently taking, including medications taken for reasons other than pain.
      Be sure to list supplements, vitamins and an over-the-counter medications.
    </div>

    <h3 class="my-6" @click="addMedication" style="cursor: pointer;">
      <v-icon class="text-h4" style="color: #2BD9EB;">
        mdi-plus-circle
      </v-icon>
      Add Medication
    </h3>
    <div class="mb-8">
      <v-row v-for="(medication, index) in medications" :key="index">
        <v-col cols="11">
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="medications[index].medication" label="* Medication" filled dense :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="medications[index].dosage" label="* Dosage" filled dense :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="medications[index].frequency" label="* Frequency" filled dense :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="medications[index].prescribing_physician" label="* Prescribing Physician" filled dense :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field v-model="medications[index].reason" label="* Reason" filled dense :rules="[rules.required]" />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex align-self-center justify-center pa-0">
          <v-icon class="text-h4 red--text" @click="deleteMedication(index)">mdi-alpha-x-box</v-icon>
        </v-col>
      </v-row>
    </div>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validateMedicationsForm"
    />
  </v-form>
</template>
<script>
import RulesMixin from '../../mixins/RulesMixin'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapActions } from 'vuex'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'MedicationsForm',
  mixins: [RulesMixin],
  components: { FormNavButtons },
  data () {
    return {
      IntakeSteps,
      medicationsForm: true,
      medications: this.$store.state.PatientIntake.medications
    }
  },
  computed: {},
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    validateMedicationsForm () {
      this.$refs.medicationsForm.validate()
      if (this.medicationsForm) {
        this.setInfo({ label: IntakeSteps.MEDICATIONS.label, data: this.medications })
        this.$emit('nextStep', IntakeSteps.MEDICATIONS.form)
      }
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.MEDICATIONS.label, data: this.medications })
      this.$emit(command)
    },
    addMedication () {
      if (!this.medications) {
        this.medications = [{ medication: '', dosage: '', frequency: '', prescribing_physician: '', reason: '' }]
      } else {
        this.medications.push({ medication: '', dosage: '', frequency: '', prescribing_physician: '', reason: '' })
      }
    },
    deleteMedication (index) {
      this.medications.splice(index, 1)
    }
  }
}
</script>

<style>

</style>
