export const Roles = {
  ADMIN: 'admin',
  BASIC: 'basic',
  CLINIC_STAFF: 'clinic_staff',
  NO_ACCESS: 'no_access',
  INDIVIDUAL: 'individual'
}

export const RoleOptions = [
  { text: 'Admin', value: Roles.ADMIN },
  { text: 'Basic', value: Roles.BASIC },
  { text: 'Clinic Staff', value: Roles.CLINIC_STAFF },
  { text: 'No Access', value: Roles.NO_ACCESS },
  { text: 'Individual', value: Roles.INDIVIDUAL }
]
