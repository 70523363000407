<template>
  <v-form ref="referralForm" v-model="referralForm">
    <h2 class="mb-2">Transfer/Referral Agreement</h2>

    <v-row dense>
      <v-col>
        <v-checkbox
          v-model="treatmentReferralInfo.certify_treatment_referral_agreement"
          :rules="[rules.required]"
          :disabled="treatmentReferralInfo.certify_treatment_referral_agreement && !!treatmentReferralInfo.signature.signature">
          <template #label>
            <div>
              <p>
                I, {{fullName}} ,understand that my medical provider may recommend me to have a referral for additional treatment.
                I understand that Northstar Healthcare may refer me to Polaris Contracting Services to assist with
                getting my medical treatment set up through one of their contracts on a lien basis. I also understand
                that I have the ability to choose my own healthcare provider through my health insurance or other
                payment option
              </p>
              <p>
                I understand that I may notify Northstar Healthcare of a different provider of my choosing to send my
                referral, if needed.
              </p>
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <SignatureBlock v-model="treatmentReferralInfo.signature" :certified="treatmentReferralInfo.certify_treatment_referral_agreement"/>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validateLienAgreementForm"
    />
  </v-form>
</template>
<script>

import RulesMixin from '../../mixins/RulesMixin'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapState, mapActions, mapGetters } from 'vuex'
import SignatureBlock from '@/components/PatientIntake/SignatureBlock'
import { formatDate } from '@/js/PatientIntake/functions'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'TreatmentReferralForm',
  mixins: [RulesMixin],
  components: { SignatureBlock, FormNavButtons },
  data () {
    return {
      IntakeSteps,
      referralForm: true
    }
  },
  computed: {
    ...mapGetters('PatientIntake', ['fullName']),
    ...mapState('PatientIntake', ['treatmentReferralInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    formatDate,
    validateLienAgreementForm () {
      this.$refs.referralForm.validate()
      if (this.referralForm && this.treatmentReferralInfo.signature.signature) {
        this.setInfo({ label: IntakeSteps.REFERRAL.label, data: this.treatmentReferralInfo })
        this.$emit('nextStep', IntakeSteps.REFERRAL.form)
      }
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.REFERRAL.label, data: this.treatmentReferralInfo })
      this.$emit(command)
    }
  }
}
</script>

<style>

</style>
