import store from '../../store'
export default function (to, from, next) {
  const routeOrgType = to.matched.reduce((type, route) => {
    if (route.meta.organizationType) {
      type = route.meta.organizationType
    }
    return type
  }, null)

  if (routeOrgType === null) {
    next()
  }

  if (store.state.Organization.type !== routeOrgType) {
    next({ replace: true, name: store.getters['Organization/defaultLandingPage'] })
  } else {
    next()
  }
}
