<template>
  <v-app id="app">
    <component v-bind:is="layout">
      <alert />
      <confirm />
      <Loading />
      <v-main>
        <router-view />
      </v-main>
    </component>
  </v-app>
</template>
<script>
import Alert from './components/Alert'
import Confirm from './components/Confirm'
import Public from './layouts/Public'
import PublicAppBar from './layouts/PublicAppBar'
import Private from './layouts/Private'
import { mapGetters } from 'vuex'
import Loading from './components/Loading'

export default {
  name: 'App',
  components: { Alert, Confirm, Loading, Public, PublicAppBar, Private },
  computed: {
    ...mapGetters({
      isLoggedIn: 'User/isLoggedIn'
    }),
    layout () {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout
      }

      return this.isLoggedIn ? 'private' : 'public'
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
