import { _axios } from '../../plugins/axios'
import store from '../../store'
import MetricsController from './MetricsController'

class AvgReferralsIn extends MetricsController {
  value = {
    labels: [],
    datasets: [
      {
        label: 'Avg. Referrals',
        data: []
      }
    ]
  }

  getData () {
    const queryString = this.formatQueryParams()
    return _axios.get(`${store.getters['Organization/apiUrl']}/metrics/avgReferralsIn${queryString}`)
      .then((response) => {
        this.processTimeSeriesResponse(response)

        return this.value
      })
  }
}

export default new AvgReferralsIn()
