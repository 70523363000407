<template>
  <v-form ref="accidentForm" v-model="accidentForm">
    <h2 class="mb-4">Accident Information</h2>
    <v-checkbox v-model="accidentInfo.not_car_accident" label="This was NOT a car accident" hide-details class="mb-4" @change="clearCarValues"/>

    <v-textarea
      v-model="accidentInfo.accident_description"
      class="mt-4"
      label="* Accident description"
      filled dense
      rows="3"
      v-if="accidentInfo.not_car_accident"
      :rules="[rules.required]"
    />

    <v-row dense class="mt-6">
      <v-col cols="12" md="3">
        <DatePicker
          ref="datepicker"
          :date="accidentInfo.date_of_accident"
          :default-to-today="false"
          label="* Date of Accident"
          @date-change="setAccidentDate"
          :dateValid="accidentDateValid"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="accidentInfo.police_report_number" label="Police Report Number" filled dense />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="accidentInfo.accident_location" label="* Location of Accident" filled dense :rules="[rules.required]"/>
      </v-col>
    </v-row>

    <div v-if="!accidentInfo.not_car_accident">
      <div class="mt-8 mb-2 font-italic">I was sitting in the:</div>
      <v-radio-group v-model="accidentInfo.sitting_location" row :rules="[rules.required]">
        <v-radio
          v-for="option in SittingLocations"
          :label="option"
          :value="option"
          :key="option"
          class="mr-6 mb-2"
        ></v-radio>
      </v-radio-group>
    </div>

    <v-textarea
      v-model="accidentInfo.car_hit_description"
      class="mt-4"
      label="* Please describe where the car was hit"
      filled dense hide-details="auto"
      rows="3"
      v-if="!accidentInfo.not_car_accident"
      :rules="[rules.required]"
    />

    <div class="font-italic mt-8">Please check all that apply</div>
    <div>
      <v-checkbox
        :label="AccidentDetails.AIR_BAGS"
        :value="AccidentDetails.AIR_BAGS"
        hide-details
        v-model="accidentInfo.accident_details"
        v-show="!accidentInfo.not_car_accident"
      />
      <v-checkbox
        v-model="accidentInfo.accident_details"
        :label="AccidentDetails.SEAT_BELT"
        hide-details
        :value="AccidentDetails.SEAT_BELT"
        v-show="!accidentInfo.not_car_accident"
      />
      <v-checkbox
        :label="AccidentDetails.TOOK_AMBULANCE"
        :value="AccidentDetails.TOOK_AMBULANCE"
        hide-details
        v-model="accidentInfo.accident_details"
      />
      <v-checkbox
        :label="AccidentDetails.VISIT_ER"
        :value="AccidentDetails.VISIT_ER"
        hide-details
        v-model="accidentInfo.accident_details"
        @change="checkHospital"
      />
      <v-text-field
        v-if="accidentInfo.accident_details.includes(AccidentDetails.VISIT_ER)"
        label="* Name of Hospital"
        filled dense hide-details="auto"
        class="mt-2"
        v-model="accidentInfo.hospital_name"
        :rules="[rules.required]"
      />
      <v-checkbox
        :label="AccidentDetails.OTHER_TREATMENT"
        :value="AccidentDetails.OTHER_TREATMENT"
        hide-details
        v-model="accidentInfo.accident_details"
        @change="checkTreatment"
      />
      <v-textarea
        v-if="accidentInfo.accident_details.includes(AccidentDetails.OTHER_TREATMENT)"
        class="mt-4"
        label="* Please explain your other treatment"
        filled dense hide-details="auto"
        rows="3"
        v-model="accidentInfo.other_treatment_description"
        :rules="[rules.required]"
      />
      <v-checkbox
        :label="AccidentDetails.OTHER_PEOPLE"
        :value="AccidentDetails.OTHER_PEOPLE"
        hide-details
        v-model="accidentInfo.accident_details"
        v-show="!accidentInfo.not_car_accident"
      />
      <div class="font-italic ml-8 mt-1" v-if="accidentInfo.accident_details.includes(AccidentDetails.OTHER_PEOPLE)">
        If they are injured and also needing treatment, please have them call 901-202-0732 to discuss getting scheduled.
      </div>
    </div>

    <v-textarea
      v-model="accidentInfo.hurt_body_description"
      class="mt-8"
      label="* What body parts hurt you? Please list all that apply"
      filled dense hide-details="auto"
      rows="3"
      :rules="[rules.required]"
    />

    <v-textarea
      v-model="accidentInfo.pain_description"
      class="mt-8"
      label="* Please describe your pain"
      filled dense hide-details="auto"
      rows="3"
      :rules="[rules.required]"
    />

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validateAccidentForm"
    />
  </v-form>
</template>
<script>
import AddressMixin from '../../mixins/AddressMixin'
import DateMixin from '../../mixins/DateMixin'
import RulesMixin from '../../mixins/RulesMixin'
import DatePicker from '@/components/PatientIntake/DatePicker'
import SittingLocations from '@/js/PatientIntake/SittingLocations'
import { AccidentDetails } from '@/js/PatientIntake/AccidentDetails'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapState, mapActions } from 'vuex'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'PatientForm',
  mixins: [AddressMixin, DateMixin, RulesMixin],
  components: { DatePicker, FormNavButtons },
  data () {
    return {
      IntakeSteps,
      accidentForm: true,
      SittingLocations,
      AccidentDetails,
      accidentDateValid: true
    }
  },
  computed: {
    ...mapState('PatientIntake', ['accidentInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    clearCarValues () {
      if (!this.accidentInfo.not_car_accident) {
        this.accidentInfo.accident_description = ''
        return
      }
      this.accidentInfo.car_hit_description = ''
      this.accidentInfo.sitting_location = ''
      this.accidentInfo.accident_details = this.accidentInfo.accident_details.filter(item => {
        return item === AccidentDetails.TOOK_AMBULANCE || item === AccidentDetails.VISIT_ER || item === AccidentDetails.OTHER_TREATMENT
      })
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.ACCIDENT.label, data: this.accidentInfo })
      this.$emit(command)
    },
    validateAccidentForm () {
      this.$refs.accidentForm.validate()
      this.validateAccidentDate()
      if (this.accidentForm && this.accidentDateValid !== 'Required') {
        this.setInfo({ label: IntakeSteps.ACCIDENT.label, data: this.accidentInfo })
        this.$emit('nextStep', IntakeSteps.ACCIDENT.form)
      }
    },
    checkHospital () {
      if (this.accidentInfo.accident_details.includes(AccidentDetails.VISIT_ER)) {
        return
      }
      this.accidentInfo.hospital_name = ''
    },
    checkTreatment () {
      if (this.accidentInfo.accident_details.includes(AccidentDetails.OTHER_TREATMENT)) {
        return
      }
      this.accidentInfo.other_treatment_description = ''
    },
    setAccidentDate (value) {
      this.accidentInfo.date_of_accident = value
    },
    validateAccidentDate () {
      this.accidentDateValid = true
      if (!this.accidentInfo.date_of_accident) {
        this.accidentDateValid = 'Required'
      }
    }
  }
}
</script>

<style>

</style>
