export default {
  methods: {
    formatAddress (address) {
      if (address) {
        return `${address.address_line1} ${address.address_line2 || ''}, ${address.city}, ${address.state?.toUpperCase()} ${address.zip}`
      }

      return ''
    }
  }
}
