<template>
  <v-container style="min-height: calc(100vh - 128px)">
    <div class="mb-12" v-if="hasAccess([Roles.ADMIN, Roles.BASIC]) && $store.getters['Organization/isHealthClinic']">
      <h1 class="mb-4">Clinic Admin Dashboard</h1>
      <ClinicAdminDashboard />
    </div>
    <div v-if="hasAccess([Roles.ADMIN, Roles.CLINIC_STAFF]) && $store.getters['Organization/isHealthClinic']" >
      <h1 class="mb-4">Clinic Staff Dashboard</h1>
      <ClinicStaffDashboard />
    </div>
<!-- TODO: when they have access to their own portal:    -->
<!-- v-if="hasAccess([Roles.INDIVIDUAL]) && $store.getters['Organization/isIndividual']"-->
    <div v-show="false">
      <h1 class="mb-4">Patient Dashboard</h1>
      <IndividualDashboard />
    </div>
  </v-container>
</template>
<script>

import ClinicAdminDashboard from '@/components/ClinicAdminDashboard/ClinicAdminDashboard'
import ClinicStaffDashboard from '@/components/ClinicStaff/ClinicStaffDashboard'
import IndividualDashboard from '@/components/Individual/IndividualDashboard'
import { mapGetters } from 'vuex'
import { Roles } from '@/js/Roles'

export default {
  name: 'Dashboard',
  components: { ClinicAdminDashboard, ClinicStaffDashboard, IndividualDashboard },
  data () {
    return {
      Roles
    }
  },
  computed: {
    ...mapGetters({ hasAccess: 'User/hasAccess' })
  },
  methods: {
  },
  mounted () {
  }
}
</script>
