'use strict'

import Vue from 'vue'
import axios from 'axios'
import router from '../router'
import event, { Events } from '../event'
import store from '../store'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_API_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

export const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (store.getters['User/isLoggedIn']) {
      config.headers.Authorization = 'Bearer ' + store.state.User.token.access_token
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  async function (error) {
    // check for errors array
    if (error.response.data.payload.errors) {
      const e = error.response.data.payload.errors.join('<br>')
      event.emit(Events.ERROR, e)
    } else if (error.response.data.payload?.debug?.message) {
      event.emit(Events.ERROR, error.response.data.payload.debug.message)
    } else if (error.response.data.payload.message) {
      event.emit(Events.ERROR, error.response.data.payload.message)
    } else if (error.response.data.message) {
      event.emit(Events.ERROR, error.response.data.payload.message)
    }

    // Do something with response error
    if (error.response.status === 401 && store.getters['User/isLoggedIn']) {
      await store.dispatch('User/logout')
      return router.push({ name: 'Login' })
    }

    return Promise.reject(error)
  }
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
