<template>
  <v-select
    :items="states"
    item-text="name"
    item-value="abbreviation"
    label="State"
    filled
    dense
    v-model="localState"
    ref="stateSelect"
    :rules="rules"
  ></v-select>
</template>

<script>
import stateList from '@/js/StateList'

export default {
  name: 'StatePicker',
  data: () => ({
    states: stateList
  }),
  props: ['state', 'rules'],
  model: {
    prop: 'state',
    event: 'state-change'
  },
  computed: {
    localState: {
      get: function () {
        return this.state
      },
      set: function (value) {
        return this.$emit('state-change', value)
      }
    }
  },
  methods: {
    clearState () {
      this.$refs.stateSelect.reset()
    }
  }
}
</script>

<style scoped>

</style>
