<template>
  <v-container>
   <PatientIntakeForm />
  </v-container>
</template>
<script>

import PatientIntakeForm from '@/components/PatientIntake/PatientIntakeForm'
import { mapActions } from 'vuex'

export default {
  name: 'PatientIntakeView',
  components: { PatientIntakeForm },
  data: () => ({}),
  created () {
    if (this.$store.state.User.patient.intake_status === null) {
      return
    }
    if (this.$store.state.User.patient.intake_status !== 'Not Started') {
      this.setAllInfo(this.$store.state.User.patient.intake)
      this.setStep(this.$store.state.User.patient.intake.step)
    }
  },
  methods: {
    ...mapActions('PatientIntake', ['setAllInfo', 'setStep'])
  }
}
</script>
