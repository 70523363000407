export default {
  format (queryParameters) {
    let queryString = '?'
    queryString += Object.keys(queryParameters)
      .filter(p => !!queryParameters[p])
      .map(key => (queryParameters[key] ? key + '=' + queryParameters[key] : null))
      .join('&')

    return queryString
  }
}
