<template>
  <div>
    <v-app-bar app flat color="primary" dark>
      <img src="../assets/Telescope 2.png" height="100%" />
    </v-app-bar>

    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'PublicAppBar'
}
</script>
