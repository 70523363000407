<template>
  <v-form ref="medicalReleaseForm" v-model="medicalReleaseForm">
    <h2 class="mb-4">Medical Release of Information</h2>

    <v-row dense>
      <v-col class="font-weight-bold">
        <span>PATIENT NAME: {{fullName()}}</span>
        <br/>
        <span>DATE OF BIRTH: {{formatDate(patientDOB())}}</span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-checkbox v-model="medicalReleaseInfo.certify_medical_release" :rules="[rules.required]" :disabled="medicalReleaseInfo.certify_medical_release && !!medicalReleaseInfo.signature.signature">
          <template #label>
            <div>
              <p>I request and authorize Northstar Healthcare to release/receive all records/information specified
                below to/from the organization agency or individual named on this request. I will request in writing
                parts I prefer to be excluded from my record. I understand that the information in my health record
                may include information relating to sexually transmitted diseases, AIDS, HIV, Alzheimer Disease,
                behavioral or mental health conditions and treatment for alcohol or drug abuse (if any). My records
                may also include copies of records from other facilities that have been used in my care. I understand
                that I have the right to revoke this authorization at any time. I understand that if I revoke the
                authorization, I must do so in writing.
              </p>

              <p>I understand that the revocation will not apply to information that has already been released in
                response to this authorization. I understand that once the above information is disclosed,
                it may be subject to re-disclosure by the recipient and may no longer be protected by the federal
                privacy law and regulations.
              </p>

              <p>I certify that this request has been made voluntarily and that the information given above is accurate to the best of my
                knowledge. I can refuse to sign the authorization. My refusal to sign this authorization will not affect my
                ability to obtain treatment, payment, or enroll in a health care plan.
              </p>
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <SignatureBlock v-model="medicalReleaseInfo.signature" :certified="medicalReleaseInfo.certify_medical_release"/>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validateMedicalReleaseForm"
    />
  </v-form>
</template>
<script>
import RulesMixin from '../../mixins/RulesMixin'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapActions, mapState, mapGetters } from 'vuex'
import SignatureBlock from '@/components/PatientIntake/SignatureBlock'
import { formatDate } from '@/js/PatientIntake/functions'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'MedicalReleaseForm',
  mixins: [RulesMixin],
  components: { FormNavButtons, SignatureBlock },
  data () {
    return {
      IntakeSteps,
      medicalReleaseForm: true
    }
  },
  computed: {
    ...mapState('PatientIntake', ['medicalReleaseInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    ...mapGetters('PatientIntake', ['patientDOB', 'fullName']),
    formatDate,
    validateMedicalReleaseForm () {
      this.$refs.medicalReleaseForm.validate()
      if (this.medicalReleaseForm && this.medicalReleaseInfo.signature.signature) {
        this.setInfo({ label: IntakeSteps.RELEASE.label, data: this.medicalReleaseInfo })
        this.$emit('nextStep', IntakeSteps.RELEASE.form)
      }
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.RELEASE.label, data: this.medicalReleaseInfo })
      this.$emit(command)
    }
  }
}
</script>

<style>

</style>
