import { DateTime } from 'luxon'
import { format } from 'date-fns'

export default {
  methods: {
    formatDate (date, format) {
      if (!date) {
        return date
      }
      const dt = DateTime.fromISO(date)
      // if (format !== null) {
      //   return dt.toFormat(format)
      // }

      return dt.toLocaleString('en-US')
    },
    formatDateTime (date) {
      if (!date) {
        return date
      }
      return new Date(date).toLocaleString()
    },
    setDate (object, key, value) {
      object[key] = value
    },
    validateDate (object) {
      let valid = true
      if (!object) {
        valid = 'Required'
      }
      return valid
    },
    // "2023-06-01T00:00:00.000000Z" to 06/01/2023
    formatCarbonToDateNoTime (date) {
      if (!date) {
        return
      }

      const parts = date.split('T')
      const newDate = new Date(parts[0])

      return format(new Date(newDate.getTime() + Math.abs(newDate.getTimezoneOffset() * 60000)), 'MM/dd/yyyy')
    },
    formatCarbonWithTime (date) {
      if (!date) {
        return
      }

      return new Date(date).toLocaleString()
    }
  }
}
