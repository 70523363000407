<template>
  <div>
    <p>Clinic Staff Dashboard</p>
  </div>
</template>
<script>

export default {
  name: 'ClinicStaffDashboard',
  components: {},
  data: () => ({
  }),
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>
