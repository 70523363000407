export function formatDate (date) {
  if (!date) {
    return ''
  }
  const parts = date.split('-')
  return [parts[1], parts[2], parts[0]].join('/')
}

export function formatCarbonDate (date) {
  if (!date) {
    return ''
  }

  return new Date(date).toLocaleString('en-US', { timeZone: 'America/North_Dakota/Center' })
}

export function formatCarbonDateNoSetTime (date) {
  if (!date) {
    return ''
  }

  if (date.includes('/')) {
    return date
  }

  return formatDate(date.substring(0, 10))
}

export function prettyDateTime (date) {
  return new Date(date).toLocaleString('en-US', {
    timeZone: 'America/North_Dakota/Center',
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }) + ' ET'
}
