import Dashboard from '@/views/Dashboard'
import PatientIntakeView from '@/views/individual/PatientIntakeView'

export default [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Telescope | Dashboard'
    }
  },
  {
    path: '/patient/intake',
    name: 'PatientIntake',
    component: PatientIntakeView,
    meta: {
      title: 'Telescope | Patient Intake Form'
    }
  }
]
