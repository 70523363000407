import Default from '../layouts/Default'
import Auth from './middleware/auth'
import RoleCheck from './middleware/roleCheck'

export default [
  {
    path: '/organization/settings',
    component: Default,
    meta: {
      title: 'Settings',
      layout: 'private',
      middleware: [Auth, RoleCheck],
      roles: ['admin']
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "OrganizationSettings" */ '../views/organization/Settings.vue'),
        name: 'OrganizationSettings'
      },
      {
        path: 'locations',
        name: 'ClinicLocations',
        component: () => import(/* webpackChunkName: "ClinicLocations" */ '../views/organization/settings/ClinicLocations.vue'),
        meta: {
          title: 'Clinic Locations'
        }
      },
      {
        path: 'service-locations',
        name: 'ServiceLocations',
        component: () => import(/* webpackChunkName: "ServiceLocations" */ '../views/organization/settings/ServiceLocations.vue'),
        meta: {
          title: 'Service Locations'
        }
      },
      {
        path: 'locations/:locationType/create',
        name: 'CreateLocationView',
        component: () => import(/* webpackChunkName: "CreateLocationView" */ '../views/organization/settings/CreateLocationView.vue'),
        meta: {
          title: 'Create Location'
        }
      },
      {
        path: 'locations/:locationType/:locationId',
        name: 'EditLocationView',
        props: true,
        component: () => import(/* webpackChunkName: "EditLocationView" */ '../views/organization/settings/EditLocationView.vue'),
        meta: {
          title: 'Edit Location'
        }
      },
      {
        path: 'users',
        name: 'OrganizationUsers',
        component: () => import(/* webpackChunkName: "OrganizationUsers" */ '../views/organization/settings/Users.vue'),
        meta: {
          title: 'Users'
        }
      },
      {
        path: 'users/:id',
        name: 'ManageUser',
        component: () => import(/* webpackChunkName: "ManageUsers" */ '../views/organization/settings/Manage.vue'),
        meta: {
          title: 'Manage User'
        }
      },
      {
        path: 'users/invite',
        name: 'InviteUsers',
        component: () => import(/* webpackChunkName: "InviteUsers" */ '../views/organization/settings/InviteUsers.vue'),
        meta: {
          title: 'Invite Users'
        }
      }
    ]
  }
]
