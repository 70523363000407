<template>
  <div>

    <application-bar v-model="drawer" />

    <left-sidebar v-model="drawer" />

    <!-- main content slot-->
    <slot></slot>

<!--    <v-footer app inset color="primary" dark class="align-content-center">-->
<!--      <div>© Telescope {{ new Date().getFullYear() }}</div>-->
<!--    </v-footer>-->
  </div>
</template>
<script>
import ApplicationBar from '../components/Navigation/ApplicationBar'
import LeftSidebar from '../components/Navigation/LeftSidebar'
export default {
  name: 'Private',
  components: { ApplicationBar, LeftSidebar },
  data () {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp
    }
  }
}
</script>
