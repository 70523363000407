<template>
  <v-form ref="lienAgreementForm" v-model="lienAgreementForm">
    <h2 class="mb-2">Polaris Lien Agreement</h2>

    <v-row dense>
      <v-col>
        <v-checkbox v-model="polarisLienInfo.certify_lien_agreement" :rules="[rules.required]" :disabled="polarisLienInfo.certify_lien_agreement && !!polarisLienInfo.signature.signature">
          <template #label>
            <div>
              <p>I hereby grant a lien to Polaris upon settlement claim as a result of an accident/illness occurring on: <span class="font-weight-bold">{{formatDate(accidentDate)}}</span></p>
              <p>I authorize and direct my attorney to pay directly to Polaris and all sums due to it for services rendered to me and to withhold such sums owed Polaris from any settlement of verdict as may be necessary to adequately protect Polaris. Furthermore, I agree that Polaris shall not be responsible and shall not pay any attorney’s fees, expenses, or costs for any claims or action I may have or the collections of any funds due from any third parties. I agree to have all my attorneys, whether retained or retained in the future, execute this document, and agree to be bound by terms contained herein until Polaris has received payment in full.</p>
              <p>I fully understand that I am directly responsible for any and all charged submitted by Polaris and that this agreement is for the protection of Polaris and in consideration of its awaiting payment. I agree to pay the reasonable costs and attorney’s fees of Polaris in order for them to collect all sums due them on my account, including any actions against me to collect such sums. I further understand that such payment is not contingent on any settlement, judgment, or verdict by which I may eventually recover said fee. If settlement is not reached in a timely manner from the date of release, payment in full is to be made by patient, parent or guardian.</p>
              <ul>
                <li class="mb-2">I consent to the treatment necessary for proper care. I consent to use and disclosure of my records (or my child’s records) to carry out treatment, to obtain payment, and for those activities and health care operations that are related to treatment or payment. My consent to disclosure of records shall be effective until I revoke it in writing.</li>
                <li class="mb-2">I understand and agree that health and accident insurance policies are an arrangement between my insurance company and me. Furthermore, I understand that Polaris will prepare any necessary reports and forms to assist me in making collection from the auto insurance company or attorney and that payment shall be paid directly to Polaris, which will be credited to my account upon receipt. However, I clearly understand and agree that all services render me are charged directly to me and that I am financially responsible for payment, I also understand that if I suspend or terminate my care and treatment, any fees for professional services rendered me will be immediately due and payable.</li>
                <li class="mb-2">I attest to the accuracy of the information on this page.</li>
              </ul>
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <div class="font-weight-bold">PATIENT ADDRESS: {{patientAddress}}</div>
    <SignatureBlock v-model="polarisLienInfo.signature" :certified="polarisLienInfo.certify_lien_agreement"/>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validateLienAgreementForm"
    />
  </v-form>
</template>
<script>

import RulesMixin from '../../mixins/RulesMixin'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapState, mapActions, mapGetters } from 'vuex'
import SignatureBlock from '@/components/PatientIntake/SignatureBlock'
import { formatDate } from '@/js/PatientIntake/functions'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'PolarisLienForm',
  mixins: [RulesMixin],
  components: { SignatureBlock, FormNavButtons },
  data () {
    return {
      IntakeSteps,
      lienAgreementForm: true
    }
  },
  computed: {
    ...mapGetters('PatientIntake', ['accidentDate', 'patientAddress']),
    ...mapState('PatientIntake', ['polarisLienInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    formatDate,
    validateLienAgreementForm () {
      this.$refs.lienAgreementForm.validate()
      if (this.lienAgreementForm && this.polarisLienInfo.signature.signature) {
        this.setInfo({ label: IntakeSteps.POLARIS_LIEN.label, data: this.polarisLienInfo })
        this.$emit('nextStep', IntakeSteps.POLARIS_LIEN.form)
      }
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.POLARIS_LIEN.label, data: this.polarisLienInfo })
      this.$emit(command)
    }
  }
}
</script>

<style>

</style>
