<template>
    <div class="mt-12">
      <v-row>
        <v-col class="text-left">
          <v-btn
            v-if="hasAccess([Roles.INDIVIDUAL]) && $store.getters['Organization/isIndividual']"
            class="save-btn"
            @click="$emit('saveAndExit')"
          >Save & Exit</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text @click="$emit('back')" :disabled="disableBack" class="mr-2">Back</v-btn>
          <v-btn color="primary" @click="$emit('nextStep')" :disabled="disableContinue">Continue</v-btn>
        </v-col>
      </v-row>
    </div>
</template>
<script>

import { Roles } from '@/js/Roles'
import { mapGetters } from 'vuex'
export default {
  name: 'FormNavButtons',
  components: {},
  props: ['disableBack', 'disableContinue'],
  data () {
    return {
      Roles
    }
  },
  computed: {
    ...mapGetters({ hasAccess: 'User/hasAccess' })
  },
  methods: {}
}
</script>

<style>
.save-btn {
  background-color: #386C03 !important;
  color: #fff !important;
}

</style>
