<template>
  <v-form ref="ptsdScreeningForm" v-model="ptsdScreeningForm">
    <h2 class="mb-2">Post-Traumatic Stress Disorder Screening Tool</h2>
    <div class="mb-12">
      <div class="font-italic">Have you / Do you experience:</div>
      <v-checkbox
        v-model="ptsdInfo.ptsdSymptoms"
        v-for="(option, index ) in PtsdSymptomOptions"
        :label="option"
        :value="option"
        :key="index"
        hide-details
        @change="resetSignature"
      />
    </div>

    <SignatureBlock v-model="ptsdInfo.signature" :certified="true" ref="signatureBlock"/>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validatePtsdScreeningForm"
    />
  </v-form>
</template>
<script>

import RulesMixin from '../../mixins/RulesMixin'
import PtsdSymptomOptions from '@/js/PatientIntake/PtsdSymptomOptions'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapActions, mapState } from 'vuex'
import SignatureBlock from '@/components/PatientIntake/SignatureBlock'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'PtsdScreeningForm',
  mixins: [RulesMixin],
  components: { FormNavButtons, SignatureBlock },
  data () {
    return {
      IntakeSteps,
      ptsdScreeningForm: true,
      PtsdSymptomOptions
    }
  },
  computed: {
    ...mapState('PatientIntake', ['ptsdInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    validatePtsdScreeningForm () {
      this.$refs.ptsdScreeningForm.validate()
      if (this.ptsdScreeningForm && this.ptsdInfo.signature.signature) {
        this.setInfo({ label: IntakeSteps.PTSD.label, data: this.ptsdInfo })
        this.$emit('nextStep', IntakeSteps.PTSD.form)
      }
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.PTSD.label, data: this.ptsdInfo })
      this.$emit(command)
    },
    resetSignature () {
      this.ptsdInfo.signature.signature = ''
      this.ptsdInfo.signature.date = ''
      this.$refs.signatureBlock.resetApplySignature()
    }
  }
}
</script>

<style>

</style>
