const PtsdSymptomOptions = [
  'Experienced or witnessed a life-threatening event',
  'Repeated, distressing memories, or dreams',
  'Acting or feeling as if the event were happening again (flashbacks or a sense of reliving it)',
  'Intense physical and/or emotional distress when you are exposed to things that remind you of the event (Sitting at a red light, driving, etc.)',
  'Avoiding thoughts, feelings, or conversations about it',
  'Avoiding activities and places or people who remind you of it',
  'Blanking on important parts of it',
  'Negative beliefs about oneself, others and the world and about the cause or consequences of the event',
  'Feeling detached from other people',
  'Inability to feel positive emotions',
  'Persistent negative emotional state',
  'Problems sleeping',
  'Irritability or outbursts of anger',
  'Reckless or self-destructive behavior',
  'Problems concentrating',
  'Feeling "on guard"',
  'An exaggerated startle response'
]

export default PtsdSymptomOptions
