const getDefaultState = () => {
  return {
    // step
    step: 1,
    // patient form
    patientInfo: {
      patient: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        dob: '',
        ssn: '',
        email: '',
        main_phone: '',
        secondary_phone: '',
        gender: '',
        gender_other: '',
        signature: '',
        address: {
          address_line1: '',
          address_line2: '',
          city: '',
          state: '',
          zip: ''
        }
      },
      legal: [],
      emergencyContact: {
        name: '',
        relationship: '',
        main_phone: '',
        secondary_phone: ''
      },
      havePhysician: false,
      primaryPhysician: {
        name: '',
        main_phone: ''
      },
      attorney: {
        organization_name: '',
        organization_main_phone: ''
      }
    },
    // accident form
    accidentInfo: {
      not_car_accident: false,
      accident_description: '',
      car_hit_description: '',
      date_of_accident: '',
      police_report_number: '',
      accident_location: '',
      sitting_location: '',
      accident_details: [],
      hurt_body_description: '',
      pain_description: '',
      hospital_name: '',
      other_treatment_description: ''
    },
    // medications form
    medications: [],
    // ptsd screening form
    ptsdInfo: {
      ptsdSymptoms: [],
      signature: {
        signature: '',
        date: ''
      }
    },
    // medical release form
    medicalReleaseInfo: {
      patient_name: '',
      patient_dob: '',
      certify_medical_release: false,
      signature: {
        signature: '',
        date: ''
      }
    },
    // privacy practices form
    privacyPracticesInfo: {
      authorizedContacts: [],
      haveRepresentative: false,
      personalRepresentative: {
        name: '',
        relationship: '',
        main_phone: ''
      },
      certify_privacy_practices: false,
      signature: {
        signature: '',
        date: ''
      }
    },
    // assignment of benefits form
    benefitsInfo: {
      certify_compensate_northstar: false,
      certify_send_check: false,
      patientName: '',
      signature: {
        signature: '',
        date: ''
      }
    },
    // northstar lien agreement form
    northstarLienInfo: {
      certify_lien_agreement: false,
      patientAddress: '',
      signature: {
        signature: '',
        date: ''
      }
    },
    // polaris lien agreement
    polarisLienInfo: {
      certify_lien_agreement: false,
      patientAddress: '',
      signature: {
        signature: '',
        date: ''
      }
    },
    // treatment referral agreement
    treatmentReferralInfo: {
      certify_treatment_referral_agreement: false,
      signature: {
        signature: '',
        date: ''
      }
    },
    // verify review signature
    reviewInfo: {
      signature: {
        signature: '',
        date: ''
      }
    }
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    fullName (state) {
      return [state.patientInfo.patient.first_name, state.patientInfo.patient.middle_name, state.patientInfo.patient.last_name, state.patientInfo.patient.suffix].filter(Boolean).join(' ')
    },
    patientDOB (state) {
      return state.patientInfo.patient.dob
    },
    patientAddress (state) {
      const address = state.patientInfo.patient.address
      if (!address.address_line1) {
        return ''
      }
      return `${address.address_line1} ${address.address_line2 ?? ''} ${address.city}, ${address.state}, ${address.zip}`
    },
    accidentDate (state) {
      return state.accidentInfo.date_of_accident
    },
    getSignature (state) {
      return state.patientInfo.patient.signature
    }
  },
  mutations: {
    step (state, payload) {
      state.step = payload
    },
    info (state, payload) {
      state[payload.label] = payload.data
    },
    allInfo (state, payload) {
      state.step = payload.step
      state.patientInfo = payload.patientInfo
      state.accidentInfo = payload.accidentInfo
      state.medications = payload.medications
      state.ptsdInfo = payload.ptsdInfo
      state.medicalReleaseInfo = payload.medicalReleaseInfo
      state.privacyPracticesInfo = payload.privacyPracticesInfo
      state.benefitsInfo = payload.benefitsInfo
      state.northstarLienInfo = payload.northstarLienInfo
      state.polarisLienInfo = payload.polarisLienInfo
      state.treatmentReferralInfo = payload.treatmentReferralInfo
      state.reviewInfo = payload.reviewInfo
    },
    populateInfo (state) {
      const fullName = [state.patientInfo.patient.first_name, state.patientInfo.patient.middle_name, state.patientInfo.patient.last_name, state.patientInfo.patient.suffix].filter(Boolean).join(' ')
      state.medicalReleaseInfo.patient_name = fullName
      state.benefitsInfo.patientName = fullName
      state.medicalReleaseInfo.patient_dob = state.patientInfo.patient.dob
      let address = state.patientInfo.patient.address
      address = `${address.address_line1} ${address.address_line2 ?? ''} ${address.city}, ${address.state}, ${address.zip}`
      state.northstarLienInfo.patientAddress = address
      state.polarisLienInfo.patientAddress = address
    },
    resetSignature (state, payload) {
      state[payload].signature.signature = ''
      state[payload].signature.date = ''
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    setStep (ctx, payload) {
      ctx.commit('step', payload)
    },
    setInfo (ctx, payload) {
      ctx.commit('info', payload)
    },
    setAllInfo (ctx, payload) {
      ctx.commit('allInfo', payload)
    },
    setPopulateInfo (ctx) {
      ctx.commit('populateInfo')
    },
    setResetSignatures (ctx, payload) {
      ctx.commit('resetSignature', payload)
    },
    setResetState (ctx) {
      ctx.commit('resetState')
    },
    // how clinic gets Intake, patient uses the user
    async getIntake (ctx, uuid) {
      const response = await window.axios.get('v1/individual/intakes/' + uuid)
      return response.data.payload.patient
    }
  }
}
