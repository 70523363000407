import { _axios } from '../../plugins/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    treatments: []
  },
  mutations: {
    setTreatments (state, treatments) {
      Vue.set(state, 'treatments', treatments)
    }
  },
  getters: {
    treatmentNameFromId: (state) => (id) => {
      const treatment = state.treatments.find(t => t.id === id)

      return treatment.name || 'Unknown'
    },
    treatmentSelectOptions (state) {
      const options = []

      state.treatments.forEach(t => {
        options.push({
          text: `${t.name}`,
          value: t.id
        })
      })

      return options
    }
  },
  actions: {
    getTreatments (ctx) {
      if (ctx.state.treatments.length === 0) {
        _axios.get('/v1/treatments')
          .then(r => {
            ctx.commit('setTreatments', r.data.payload)
          })
      }
    }
  }
}
