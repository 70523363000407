import Token from './middleware/token'

export default [
  {
    path: '/case/:caseId/lien/sign',
    name: 'SignLien',
    component: () => import(/* webpackChunkName: "SignLien" */ '../views/case/lien/SignLien.vue'),
    meta: {
      title: 'Sign Lien',
      middleware: [Token],
      layout: 'public-app-bar'
    }
  }
]
