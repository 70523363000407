import Default from '../layouts/Default'
import Auth from './middleware/auth'
import OrganizationType from './middleware/organizationType'
import clinicPrivateRoutes from './clinicPrivateRoutes'
import attorneyPrivateRoutes from './attorneyPrivateRoutes'
import individualPrivateRoutes from './individualPrivateRoutes'
import { ORGANIZATION_TYPES } from '../store/modules/Organization'

export default [
  {
    path: '/private',
    component: Default,
    meta: {
      title: 'Telescope',
      layout: 'private',
      middleware: [Auth, OrganizationType]
    },
    children: [
      {
        path: '',
        component: Default,
        meta: {
          organizationType: ORGANIZATION_TYPES.CLINIC
        },
        children: [
          ...clinicPrivateRoutes
        ]
      },
      {
        path: '/attorney',
        component: Default,
        meta: {
          organizationType: ORGANIZATION_TYPES.LAW_FIRM
        },
        children: [
          ...attorneyPrivateRoutes
        ]
      },
      {
        path: '/individual',
        component: Default,
        meta: {
          organizationType: ORGANIZATION_TYPES.INDIVIDUAL
        },
        children: [
          ...individualPrivateRoutes
        ]
      }
    ]
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../views/user/Profile.vue'),
    meta: {
      title: 'Profile',
      middleware: [Auth]
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "Unauthorized" */ '../views/Unauthorized.vue')
  }
]
