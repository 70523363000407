<template>
  <v-dialog persistent :value="show" max-width="600">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-progress-linear rounded height="20" indeterminate/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span class="headline">
                {{ message }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import event, { Events } from '../event'

export default {
  data: () => ({
    show: false,
    message: 'Loading...'
  }),
  created () {
    event.on(Events.LOADING, (show, message) => {
      this.show = show
      this.message = message || 'Loading...'
    })
  }
}
</script>
