import QueryString from '../../library/QueryString'

export default {
  namespaced: true,
  state: {
    cases: [],
    casesCount: 0,
    availableAppointmentStatuses: {
      requested: 'requested',
      scheduled: 'scheduled',
      completed: 'completed',
      canceled: 'canceled',
      billed: 'billed',
      missed: 'missed'
    }
  },
  getters: {
    appointmentStatuses: (state) => (status) => {
      if (status === undefined) {
        return {
          scheduled: state.availableAppointmentStatuses.scheduled,
          completed: state.availableAppointmentStatuses.completed,
          canceled: state.availableAppointmentStatuses.canceled,
          billed: state.availableAppointmentStatuses.billed,
          missed: state.availableAppointmentStatuses.missed
        }
      }
      switch (status) {
        case state.availableAppointmentStatuses.requested:
          return {
            scheduled: state.availableAppointmentStatuses.scheduled,
            completed: state.availableAppointmentStatuses.completed,
            canceled: state.availableAppointmentStatuses.canceled,
            billed: state.availableAppointmentStatuses.billed,
            missed: state.availableAppointmentStatuses.missed
          }

        case state.availableAppointmentStatuses.scheduled:
          return {
            completed: state.availableAppointmentStatuses.completed,
            canceled: state.availableAppointmentStatuses.canceled,
            missed: state.availableAppointmentStatuses.missed
          }

        case state.availableAppointmentStatuses.completed:
        case state.availableAppointmentStatuses.canceled:
        case state.availableAppointmentStatuses.missed:
          return {
            requested: state.availableAppointmentStatuses.requested
          }
      }
    }
  },
  mutations: {
    setCases (state, cases) {
      state.cases = cases
    },
    setCasesCount (state, count) {
      state.casesCount = count
    }
  },
  actions: {
    async getCases (ctx, params) {
      let queryString = ''
      if (params) {
        queryString = QueryString.format(params)
      }
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case' + encodeURI(queryString))
      ctx.commit('setCases', response.data.payload.cases)
      ctx.commit('setCasesCount', response.data.payload.totalCount)

      return response.data.payload
    },
    async getCase (ctx, caseId) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId)
      return response.data.payload
    },
    async updateCase (ctx, updatedCaseData) {
      const response = await window.axios.put(
        ctx.rootGetters['Organization/apiUrl'] + '/case/' + updatedCaseData.id,
        updatedCaseData
      )
      const updatedCase = response.data.payload

      // check if the case exists in the cases array and update it if it does
      const updatedCaseIndex = ctx.state.cases.findIndex(c => c.id === updatedCase.id)
      if (updatedCaseIndex !== -1) {
        const cases = ctx.state.cases
        cases[updatedCaseIndex] = updatedCase
      }

      return updatedCase
    },
    transitionCase (ctx, { caseId, transition }) {
      return window.axios.put(`${ctx.rootGetters['Organization/apiUrl']}/case/${caseId}/transition`, { transition })
    },
    async getCaseLien (ctx, caseId) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/lien')
      return response.data.payload
    },
    async requestLienSignatures (ctx, caseId) {
      const response = await window.axios.post(
        ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/lien/request-signatures')
      return response.data.payload
    },
    async resendSignatureRequest (ctx, {
      caseId,
      to
    }) {
      const response = await window.axios.post(
        ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/lien/resend-signature-request',
        { to }
      )
      return response.data.payload
    },
    async getCaseLogs (ctx, { caseId, params }) {
      let queryString = ''
      if (params) {
        queryString = QueryString.format(params)
      }
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/logs' + encodeURI(queryString))
      return response.data.payload
    },
    async getCaseReferralsOut (ctx, caseId) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/referrals-out')
      return response.data.payload
    },
    async getCaseAppointments (ctx, { caseId, params }) {
      let queryString = ''
      if (params) {
        queryString = QueryString.format(params)
      }
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/appointments' + encodeURI(queryString))
      return response.data.payload
    },
    async getCaseFirmMembers (ctx, caseId) {
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/members')
      return response.data.payload
    },
    async getReferralOutLogs (ctx, { caseId, params }) {
      let queryString = ''
      if (params) {
        queryString = QueryString.format(params)
      }
      const response = await window.axios.get(ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/referrals-out/logs' + encodeURI(queryString))
      return response.data.payload
    },
    async getPatient (ctx, { caseId, patientId }) {
      const response = await window.axios.get(
        ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/patient/' + patientId)
      return response.data.patient
    },
    async updatePatient (ctx, { caseId, patient }) {
      const response = await window.axios.put(
        ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/patient/' + patient.id,
        patient
      )
      return response.data.payload
    },
    async completeInOfficeIntake (ctx, caseId) {
      const response = await window.axios.put(
        ctx.rootGetters['Organization/apiUrl'] + '/case/' + caseId + '/in-office-intake')
      return response.data.payload
    }
  }
}
