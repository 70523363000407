const IntakeSteps = {
  PATIENT: { form: 'Patient Form', label: 'patientInfo' },
  ACCIDENT: { form: 'Accident Form', label: 'accidentInfo' },
  MEDICATIONS: { form: 'Medications', label: 'medications' },
  PTSD: { form: 'PTSD Screening', label: 'ptsdInfo' },
  RELEASE: { form: 'Medical Release', label: 'medicalReleaseInfo' },
  PRIVACY: { form: 'Privacy Practices', label: 'privacyPracticesInfo' },
  BENEFITS: { form: 'Assignment of Benefits', label: 'benefitsInfo' },
  NORTHSTAR_LIEN: { form: 'Northstar Lien Form', label: 'northstarLienInfo' },
  POLARIS_LIEN: { form: 'Polaris Lien Form', label: 'polarisLienInfo' },
  REFERRAL: { form: 'Treatment/Referral Form', label: 'treatmentReferralInfo' },
  REVIEW: { form: 'Review', label: 'reviewInfo' }
}

export default IntakeSteps
