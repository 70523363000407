<template>
  <v-app-bar app flat color="primary" dark>
    <v-app-bar-nav-icon v-if="!value" @click="$emit('input', true)" />
    <div
      v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]) && !$store.getters['Organization/isIndividual']"
      class="pb-2"
    >
      <v-select
        v-model="currentLocationId"
        :items="updatedLocations"
        label="Active Location"
        item-text="name"
        item-value="id"
        dense filled
        class="mt-2 d-inline-block maxWidth"
        hide-details
        @input="setAndFilterLocations"
      />
    </div>
    <v-spacer></v-spacer>
<!--TODO update when individuals access their own profile -->
    <v-avatar
      class="pointer"
      @click="profile"
      color="grey darken-1"
      size="40"
      v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]) && !$store.getters['Organization/isIndividual']" >
      <span class="white--text text-h5">{{ $store.getters['User/initials'] }}</span>
    </v-avatar>

    <!--TODO update when individuals access their own profile -->
    <v-menu v-if="hasAccess([Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF]) && !$store.getters['Organization/isIndividual']">
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>fas fa-ellipsis-v</v-icon>
        </v-btn>
      </template>

      <v-list>
        <template v-for="(key) in Object.keys(availableMenuItems)">
          <v-subheader :key="key">{{ key }}</v-subheader>
          <v-list-item :key="key+index" :to="item.path" v-for="(item, index) in availableMenuItems[key]">
            <v-list-item-icon>
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon small>fas fa-sign-out-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
</style>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Roles } from '@/js/Roles'

export default {
  name: 'ApplicationBar',
  props: {
    value: {
      type: Boolean,
      default () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      required: false
    }
  },
  data () {
    return {
      Roles,
      allMenuItems: {
        'Organization Settings': [
          {
            text: 'Organization Settings',
            path: { name: 'OrganizationSettings' },
            icon: 'fa-sliders-h',
            roles: [Roles.ADMIN],
            show: !this.$store.getters['Organization/isIndividual']
          },
          {
            text: 'Clinic Locations',
            path: { name: 'ClinicLocations' },
            icon: 'fa-clinic-medical',
            roles: [Roles.ADMIN],
            show: this.$store.getters['Organization/isHealthClinic']
          },
          {
            text: 'Service Locations',
            path: { name: 'ServiceLocations' },
            icon: 'fa-clinic-medical',
            roles: [Roles.ADMIN],
            show: this.$store.getters['Organization/isHealthClinic']
          },
          {
            text: 'User Management',
            path: { name: 'OrganizationUsers' },
            icon: 'fa-users-cog',
            roles: [Roles.ADMIN],
            show: !this.$store.getters['Organization/isIndividual']
          }
        ],
        // TODO: add Roles.Individual when users can access their patient portals
        'Personal Settings': [
          {
            text: 'Profile',
            path: { name: 'Profile' },
            icon: 'fa-user-cog',
            roles: [Roles.ADMIN, Roles.BASIC, Roles.CLINIC_STAFF],
            show: true
          }
        ]
      },
      currentLocationId: 'all',
      updatedLocations: []
    }
  },
  computed: {
    ...mapState({
      locations: state => state.Organization.locations
    }),
    ...mapGetters({
      hasAccess: 'User/hasAccess',
      activeLocationId: 'User/activeLocationId',
      caseSearchParams: 'User/getCaseSearchParams'
    }),
    availableMenuItems () {
      const items = {}

      Object.keys(this.allMenuItems).forEach(k => {
        this.allMenuItems[k].forEach(m => {
          if (m.show && this.hasAccess(m.roles)) {
            if (!items[k]) {
              items[k] = []
            }

            items[k].push(m)
          }
        })
      })

      return items
    }
  },
  mounted () {
    this.getLocations()
  },
  methods: {
    profile () {
      if (this.$route.path !== '/user/profile') {
        this.$router.push('/user/profile')
      }
    },
    logout () {
      this.$store
        .dispatch('User/logout')
        .then(() => {
          this.$router.push({ name: 'Login' })
        })
    },
    ...mapActions('User', ['setActiveLocationId']),
    setAndFilterLocations () {
      // when user changes the active location, it will automatically send new params and get the cases
      let currentLocation = 'all'
      if (this.currentLocationId !== 'all') {
        currentLocation = this.currentLocationId
      }
      this.caseSearchParams.activeLocationId = currentLocation
      this.setActiveLocationId(this.caseSearchParams)
    },
    addAllLocation () {
      this.updatedLocations = this.locations
      this.updatedLocations.unshift({ name: 'All', id: 'all' })
    },
    getLocations () {
      this.$store.dispatch('Organization/getLocations')
      this.addAllLocation()
      if (this.activeLocationId) {
        this.currentLocationId = this.activeLocationId
      }
    }
  }
}
</script>
<style scoped>
  .maxWidth {
    width: 12rem;
  }
</style>
