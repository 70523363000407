export default [
  {
    path: '/attorney/cases',
    name: 'AttorneyCases',
    component: () => import(/* webpackChunkName: "CreatePatient" */ '../views/attorney/ActiveCases.vue'),
    meta: {
      title: 'Telescope | Case'
    }
  },
  {
    path: '/attorney/case/:caseId',
    name: 'AttorneyCaseView',
    props: true,
    component: () => import(/* webpackChunkName: "CreatePatient" */ '../views/attorney/View.vue'),
    meta: {
      title: 'Telescope | Case'
    }
  }
]
