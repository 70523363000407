<template>
  <div class="chart-container">
    <canvas :id="id"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto'

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
}

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey
]

export default {
  name: 'Chart',
  props: {
    id: {
      type: String,
      required: true
    },
    chartType: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      ctx: null,
      chart: null,
      chartJsOptions: {
        aspectRatio: 16 / 9
      }
    }
  },
  computed: {
    chartOptions () {
      const data = this.data
      if (data.datasets?.length > 1) {
        data.datasets.forEach((dataset, index) => {
          const color = this.namedColor(index)
          dataset.backgroundColor = color
          dataset.borderColor = color
        })
      }

      return {
        type: this.chartType,
        data: data,
        options: {
          ...this.chartJsOptions,
          ...this.options
        }
      }
    }
  },
  mounted () {
    this.ctx = document.getElementById(this.id).getContext('2d')
    this.chart = new Chart(this.ctx, this.chartOptions)
  },
  methods: {
    namedColor (index) {
      return NAMED_COLORS[index % NAMED_COLORS.length]
    }
  }
}
</script>
<style scoped>
  .chart-container {
    position: relative;
  }
</style>
