import { _axios } from '../../plugins/axios'
import store from '../../store'
import MetricsController from './MetricsController'

class AvgBilledPerCase extends MetricsController {
  canGroupByLocation = false

  getData () {
    const queryString = this.formatQueryParams()
    return _axios.get(`${store.getters['Organization/apiUrl']}/metrics/avgBilledPerCase${queryString}`)
      .then((response) => {
        this.value = response.data.payload.data.avg

        return this.value
      })
  }
}

export default new AvgBilledPerCase()
