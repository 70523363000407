<template>
  <v-form ref="reviewForm" v-model="reviewForm">
    <h1 class="mb-4">Review Patient Intake Form</h1>

<!--    PATIENT INFO -->
    <h2 class="mb-2">Patient Details</h2>
    <v-row dense>
      <v-col>
        <div class="reviewDivs">
          <div><span>NAME:</span> {{fullName}}</div>
          <div><span>DOB:</span> {{formatDate(patientInfo.patient.dob)}}</div>
          <div><span>MAIN PHONE:</span> {{patientInfo.patient.main_phone}}</div>
          <div><span>SECONDARY PHONE:</span> {{patientInfo.patient.secondary_phone}}</div>
          <div><span>SSN:</span> {{patientInfo.patient.ssn}}</div>
          <div><span>EMAIL:</span> {{patientInfo.patient.email}}</div>
          <div><span>GENDER:</span> {{patientInfo.patient.gender}}<div class="d-inline" v-if="patientInfo.patient.gender_other">; {{patientInfo.patient.gender_other}}</div></div>
          <div><span>HOME ADDRESS:</span> {{patientAddress}}</div>
          <v-checkbox
            v-for="option in PatientLegalOptions"
            :label="option.text"
            :key="option.value"
            :disabled="true"
            hide-details
            :input-value="patientInfo.legal.includes(option.value)"
          />
        </div>
      </v-col>
      <v-col>
        <div class="reviewDivs">
          <div><span>EMERGENCY CONTACT:</span> {{patientInfo.emergencyContact.name}}</div>
          <div><span>RELATIONSHIP:</span> {{patientInfo.emergencyContact.relationship}}</div>
          <div><span>MAIN PHONE:</span> {{patientInfo.emergencyContact.main_phone}} </div>
          <div v-if="patientInfo.emergencyContact.secondary_phone"><span>SECONDARY PHONE:</span> {{patientInfo.emergencyContact.secondary_phone}}</div>
          <div class="mt-4"><span>PRIMARY PHYSICIAN:</span> {{patientInfo.primaryPhysician.name}}</div>
          <div><span>PHONE:</span> {{patientInfo.primaryPhysician.main_phone}}</div>
          <div v-if="patientInfo.attorney.organization_name" class="mt-4"><span>ATTORNEY:</span> {{patientInfo.attorney.organization_name}}</div>
          <div v-if="patientInfo.attorney.organization_main_phone"><span>PHONE:</span> {{patientInfo.attorney.organization_main_phone}}</div>
        </div>
      </v-col>
    </v-row>

<!--ACCIDENT DETAILS-->
    <h2 class="mt-10 mb-2">Accident Details</h2>
    <!--          TODO add information if this was not a car accident-->
    <v-row dense>
      <v-col>
        <div class="reviewDivs">
          <div><span>DATE OF ACCIDENT:</span> {{formatDate(accidentInfo.date_of_accident)}}</div>
          <div><span>POLICE REPORT NUMBER:</span> {{accidentInfo.police_report_number}}</div>
          <div><span>LOCATION OF ACCIDENT:</span> {{accidentInfo.accident_location}}</div>
          <div v-if="!accidentInfo.not_car_accident"><span>CAR POSITION:</span> {{accidentInfo.sitting_location}}</div>
          <div class="mt-3"><span>OTHER TREATMENT:</span> {{accidentInfo.other_treatment_description}}</div>
        </div>
      </v-col>
      <v-col>
        <div class="reviewDivs">
          <v-checkbox
            v-if="!accidentInfo.not_car_accident"
            :label="AccidentDetails.AIR_BAGS"
            :disabled="true"
            hide-details
            class="mt-0"
            :input-value="accidentInfo.accident_details.includes(AccidentDetails.AIR_BAGS)"
          />
          <v-checkbox
            v-if="!accidentInfo.not_car_accident"
            :label="AccidentDetails.SEAT_BELT"
            :disabled="true"
            hide-details
            class="mt-0"
            :input-value="accidentInfo.accident_details.includes(AccidentDetails.SEAT_BELT)"
          />
          <v-checkbox
            :label="AccidentDetails.TOOK_AMBULANCE"
            :disabled="true"
            hide-details
            class="mt-0"
            :input-value="accidentInfo.accident_details.includes(AccidentDetails.TOOK_AMBULANCE)"
          />
          <v-checkbox
            :label="AccidentDetails.VISIT_ER"
            :disabled="true"
            hide-details
            class="mt-0"
            :input-value="accidentInfo.accident_details.includes(AccidentDetails.VISIT_ER)"
          />
          <v-checkbox
            :label="AccidentDetails.OTHER_TREATMENT"
            :disabled="true"
            hide-details class="mt-0"
            :input-value="accidentInfo.accident_details.includes(AccidentDetails.OTHER_TREATMENT)"
          />
          <v-checkbox
            v-if="!accidentInfo.not_car_accident"
            :label="AccidentDetails.OTHER_PEOPLE"
            :disabled="true"
            :input-value="accidentInfo.accident_details.includes(AccidentDetails.OTHER_PEOPLE)"
            hide-details class="mt-0"
          />
        </div>
      </v-col>
    </v-row>
    <div class="reviewDivs">
      <div class="mt-3" v-if="!accidentInfo.not_car_accident"><span>CAR HIT DESCRIPTION: </span> {{accidentInfo.car_hit_description}}</div>
      <div class="mt-3" v-if="accidentInfo.not_car_accident"><span>ACCIDENT DESCRIPTION: </span> {{accidentInfo.accident_description}}</div>
<!--      TODO add hospital name-->
      <div class="mt-3"><span>HURT BODY DESCRIPTION: </span> {{accidentInfo.hurt_body_description}}</div>
      <div class="mt-3"><span>PAIN DESCRIPTION: </span> {{accidentInfo.pain_description}}</div>
    </div>

<!--    MEDICATIONS-->
    <h2 class="mt-10 mb-2">Medications</h2>
    <div class="reviewDivs" v-if="medications.length === 0">
      <div><span>NO MEDICATIONS</span></div>
    </div>
    <v-row dense>
      <v-col v-for="( medication, index) in medications" :key="index" :cols="6" class="mb-3">
        <div class="reviewDivs">
          <div><span>MEDICATION #{{index + 1}}</span> {{medication.medication}}</div>
          <div><span>DOSAGE:</span> {{medication.dosage}}, <span>FREQUENCY:</span> {{medication.frequency}}</div>
          <div><span>PRESCRIBING PHYSICIAN:</span> {{medication.prescribing_physician}}</div>
          <div><span>REASON: </span>{{ medication.reason}}</div>
        </div>
      </v-col>
    </v-row>

<!--PTSD SCREENING-->
    <h2 class="mt-10 mb-2">PTSD Screening</h2>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="ptsdInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4"><span class="font-italic">{{ptsdInfo.signature.signature}}</span> {{ptsdInfo.signature.date}}</span>
    </div>
    <v-row>
      <v-col>
        <div class="reviewDivs">
          <div><span>SYMPTOMS:</span></div>
          <v-checkbox
            v-for="option in PtsdSymptomOptions"
            :key="option"
            :label="option"
            :disabled="true"
            hide-details
            :input-value="ptsdInfo.ptsdSymptoms.includes(option)"
          />
        </div>
      </v-col>
    </v-row>

<!--    MEDICAL RELEASE -->
    <h2 class="mt-10 mb-2">Medical Release</h2>
    <div class="reviewDivs">
      <div><span>PATIENT NAME:</span> {{medicalReleaseInfo.patient_name}}</div>
      <div><span>PATIENT DOB:</span> {{formatDate(medicalReleaseInfo.patient_dob)}}</div>
      <v-checkbox
        label="Confirmed Medical Release Acknowledgement"
        :disabled="true"
        hide-details class="mt-0"
        :input-value="medicalReleaseInfo.certify_medical_release"
      />
    </div>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="medicalReleaseInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4"><span class="font-italic">{{medicalReleaseInfo.signature.signature}}</span> {{medicalReleaseInfo.signature.date}}</span>
    </div>

<!--    PRIVACY PRACTICES -->
    <h2 class="mt-10 mb-2">Privacy Practices</h2>
    <v-row dense>
      <v-col v-for="(contact, index ) in privacyPracticesInfo.authorizedContacts" :key="index" cols="6" class="mb-3">
        <div class="reviewDivs">
          <div><span>AUTHORIZED CONTACT #{{index + 1}}:</span> {{contact.name}}</div>
          <div><span>RELATIONSHIP:</span> {{contact.relationship}}, <span>PHONE:</span> {{contact.main_phone}}</div>
        </div>
      </v-col>
      <v-col v-if="privacyPracticesInfo.authorizedContacts.length === 0" class="mb-1">
        <div class="reviewDivs">
          <span>NO AUTHORIZED CONTACTS</span>
        </div>
      </v-col>
    </v-row>
    <div class="reviewDivs" v-if="privacyPracticesInfo.personalRepresentative.name">
      <div><span>PERSONAL REPRESENTATIVE: </span> {{privacyPracticesInfo.personalRepresentative.name}}</div>
      <div class="mb-3"><span>RELATIONSHIP:</span> {{privacyPracticesInfo.personalRepresentative.relationship}},
        <span>PHONE:</span> {{privacyPracticesInfo.personalRepresentative.main_phone}}</div>
      </div>
    <div class="reviewDivs mb-1" v-if="!privacyPracticesInfo.personalRepresentative.name">
      <span>NO PERSONAL REPRESENTATIVE</span>
    </div>
    <div class="reviewDivs">
      <v-checkbox
        label="Confirmed Acknowledgement of Receipt of Privacy Practices"
        :disabled="true"
        hide-details
        class="mt-0"
        :input-value="privacyPracticesInfo.certify_privacy_practices"
      />
    </div>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="privacyPracticesInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4"><span class="font-italic">{{privacyPracticesInfo.signature.signature}}</span> {{privacyPracticesInfo.signature.date}}</span>
    </div>

<!--    BENEFITS -->
    <h2 class="mt-10 mb-2">Assignment of Benefits</h2>
    <div class="reviewDivs">
      <div><span>PATIENT NAME:</span> {{benefitsInfo.patientName}}</div>
      <v-checkbox
        label="Confirmed Assignment of Benefits Acknowledgements"
        :disabled="true"
        hide-details
        :input-value="benefitsInfo.certify_send_check && benefitsInfo.certify_compensate_northstar"
      />
    </div>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="benefitsInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4"><span class="font-italic">{{benefitsInfo.signature.signature}}</span> {{benefitsInfo.signature.date}}</span>
    </div>

<!-- Northstar LIEN -->
    <h2 class="mt-10 mb-2">Northstar Lien Agreement</h2>
    <div class="reviewDivs">
      <div><span>PATIENT ADDRESS:</span> {{northstarLienInfo.patientAddress}}</div>
      <v-checkbox
        label="Confirmed Northstar Lien Agreement"
        :disabled="true"
        hide-details
        class="mt-0"
        :input-value="northstarLienInfo.certify_lien_agreement"
      />
    </div>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="northstarLienInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4"><span class="font-italic">{{northstarLienInfo.signature.signature}}</span> {{northstarLienInfo.signature.date}}</span>
    </div>

    <!-- Polaris LIEN -->
    <h2 class="mt-10 mb-2">Polaris Lien Agreement</h2>
    <div class="reviewDivs">
      <div><span>PATIENT ADDRESS:</span> {{polarisLienInfo.patientAddress}}</div>
      <v-checkbox
        label="Confirmed Polaris Lien Agreement"
        :disabled="true"
        hide-details
        class="mt-0"
        :input-value="polarisLienInfo.certify_lien_agreement"
      />
    </div>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="polarisLienInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4"><span class="font-italic">{{polarisLienInfo.signature.signature}}</span> {{polarisLienInfo.signature.date}}</span>
    </div>

<!-- Treatment Referral   -->
    <h2 class="mt-10 mb-2">Treatment/Referral Agreement</h2>
    <div class="reviewDivs">
      <v-checkbox
        label="Confirmed Treatment/Referral Agreement"
        :disabled="true"
        hide-details
        class="mt-0"
        :input-value="treatmentReferralInfo.certify_treatment_referral_agreement"
      />
    </div>
    <div class="reviewDivs d-flex">
      <v-checkbox label="Patient Signature:" :disabled="true" hide-details :input-value="treatmentReferralInfo.signature.signature" class="mb-4"/>
      <span class="mt-1 ml-4">
        <span class="font-italic">{{treatmentReferralInfo.signature.signature}}</span>
        {{treatmentReferralInfo.signature.date}}</span>
    </div>

<!-- Verify Signature   -->
    <h2 class="mt-8 mb-2">Signature</h2>
    <div class="font-italic mb-2">I, {{fullName}}, verify that that all information and signatures in this review are accurate</div>
    <v-row dense>
      <v-col cols="9">
        <v-text-field
          v-model="reviewInfo.signature.signature"
          label="* Signature"
          filled dense
          hint="First and Last name"
          :rules="[
            rules.required,
            signatureMatchesName || 'Signature does not match name in Patient Form'
            ]"
          @change="setSignature"
        />
      </v-col>
      <v-col class="d-flex align-center justify-center">
        <div class="font-weight-bold mb-4"> {{reviewInfo.signature.date}}</div>
      </v-col>
    </v-row>

    <div class="mt-12">
      <v-row>
        <v-col class="text-left">
          <v-btn
            class="save-btn" @click="$emit('saveAndExit')"
            v-if="hasAccess([Roles.INDIVIDUAL]) && $store.getters['Organization/isIndividual']"
          >Save & Exit</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text @click="$emit('back')" class="mr-2">Back</v-btn>
          <v-btn
            color="primary"
            @click="submitForm"
            :loading="loading"
            v-if="hasAccess([Roles.INDIVIDUAL]) && $store.getters['Organization/isIndividual']"
          >Submit</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import RulesMixin from '../../mixins/RulesMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import PatientLegalOptions from '@/js/PatientIntake/PatientLegalOptions'
import { AccidentDetails } from '@/js/PatientIntake/AccidentDetails'
import PtsdSymptomOptions from '@/js/PatientIntake/PtsdSymptomOptions'
import { formatDate } from '@/js/PatientIntake/functions'
import { Roles } from '@/js/Roles'

export default {
  name: 'ReviewForm',
  mixins: [RulesMixin],
  components: { },
  data () {
    return {
      reviewForm: true,
      PatientLegalOptions,
      AccidentDetails,
      PtsdSymptomOptions,
      Roles,
      loading: false
    }
  },
  computed: {
    ...mapState('PatientIntake', [
      'patientInfo',
      'accidentInfo',
      'medications',
      'ptsdInfo',
      'medicalReleaseInfo',
      'privacyPracticesInfo',
      'benefitsInfo',
      'northstarLienInfo',
      'polarisLienInfo',
      'treatmentReferralInfo',
      'reviewInfo'
    ]),
    ...mapGetters('PatientIntake', ['fullName', 'patientAddress']),
    ...mapGetters({ hasAccess: 'User/hasAccess' }),
    signatureMatchesName () {
      return this.reviewInfo.signature.signature.toLowerCase().trim() === this.patientInfo.patient.signature.toLowerCase()
    }
  },
  methods: {
    formatDate,
    ...mapActions('PatientIntake', ['setInfo']),
    setSignature () {
      if (!this.reviewInfo.signature.signature) {
        this.reviewInfo.signature.date = ''
        return
      }
      this.reviewInfo.signature.signature = this.reviewInfo.signature.signature.trim()
      this.reviewInfo.signature.date = new Date().toLocaleString('en-US')
    },
    submitForm () {
      this.$refs.reviewForm.validate()
      if (!this.reviewForm) {
        return
      }
      this.loading = true
      this.setSignature()
      this.setInfo({ label: 'reviewInfo', data: this.reviewInfo })
      this.$emit('submitForm')
    }
  }
}
</script>

<style>
.save-btn {
  background-color: #386C03 !important;
  color: #fff !important;
}
.reviewDivs > div {
  margin-bottom: 5px;
}

.reviewDivs > .v-input--checkbox .v-label {
  color: #000 !important;
}

.reviewDivs > .v-input--checkbox {
  margin-top: 0;
}

.reviewDivs span {
  font-weight: bold !important;
}
</style>
