<template>
  <div>
  <h1 class="text-center">Patient Intake Form</h1>
   <v-alert v-show="showError" outlined type="error" border="left" dismissible>
     <div class="text-h6">INCOMPLETE FORMS:</div>
     <div v-for="form in invalidForms" :key="form">
       <span>{{form}}</span>
     </div>
   </v-alert>
  <v-stepper v-model="step" flat outlined :alt-labels="$vuetify.breakpoint.mdAndUp" non-linear horizontal>
    <v-stepper-header v-if="step !== 12">
      <v-stepper-step v-for="(step, index) in visibleSteps" :editable="step.editable" :step="index + 1" :key="index" @click="setStepClick(step.text)">
        {{ step.text }}
      </v-stepper-step>

    </v-stepper-header>
    <v-stepper-items>

      <v-stepper-content v-if="stepVisible(IntakeSteps.PATIENT.form)" :step="stepValue(IntakeSteps.PATIENT.form)">
        <PatientForm ref="patientForm" @saveAndExit="saveAndExit" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.ACCIDENT.form)" :step="stepValue(IntakeSteps.ACCIDENT.form)">
        <AccidentForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep"/>
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.MEDICATIONS.form)" :step="stepValue(IntakeSteps.MEDICATIONS.form)">
        <MedicationsForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.PTSD.form)" :step="stepValue(IntakeSteps.PTSD.form)">
        <PtsdScreeningForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.RELEASE.form)" :step="stepValue(IntakeSteps.RELEASE.form)">
        <MedicalReleaseForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.PRIVACY.form)" :step="stepValue(IntakeSteps.PRIVACY.form)">
        <PrivacyPracticesForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.BENEFITS.form)" :step="stepValue(IntakeSteps.BENEFITS.form)">
        <AssignmentOfBenefitsForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.NORTHSTAR_LIEN.form)" :step="stepValue(IntakeSteps.NORTHSTAR_LIEN.form)">
        <NorthstarLienForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.POLARIS_LIEN.form)" :step="stepValue(IntakeSteps.POLARIS_LIEN.form)">
        <PolarisLienForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.REFERRAL.form)" :step="stepValue(IntakeSteps.REFERRAL.form)">
        <TreatmentReferralForm @saveAndExit="saveAndExit" @back="back" @nextStep="nextStep" />
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible(IntakeSteps.REVIEW.form)" :step="stepValue(IntakeSteps.REVIEW.form)">
        <ReviewForm ref="reviewForm" @saveAndExit="saveAndExit" @back="back" @submitForm="submitIntakeForm"/>
      </v-stepper-content>

<!--      TODO: add if user can edit form once submitted-->
      <v-stepper-content v-show="showSuccess" :step="12">
        <v-form ref="successForm" v-model="successForm">
          <h2 class="mb-2 text-center" style="color: #386C03 !important;">Your Patient Intake Form has been successfully submitted!</h2>

          <div class="mt-12 text-center">
            <v-btn class="save-btn" @click="logout">Exit</v-btn>
          </div>
        </v-form>
      </v-stepper-content>

    </v-stepper-items>
  </v-stepper>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import AddressMixin from '../../mixins/AddressMixin'
import DateMixin from '../../mixins/DateMixin'
import RulesMixin from '../../mixins/RulesMixin'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'
import PatientForm from '@/components/PatientIntake/PatientForm'
import AccidentForm from '@/components/PatientIntake/AccidentForm'
import MedicationsForm from '@/components/PatientIntake/MedicationsForm'
import PtsdScreeningForm from '@/components/PatientIntake/PtsdScreeningForm'
import MedicalReleaseForm from '@/components/PatientIntake/MedicalReleaseForm'
import PrivacyPracticesForm from '@/components/PatientIntake/PrivacyPracticesForm'
import AssignmentOfBenefitsForm from '@/components/PatientIntake/AssignmentOfBenefitsForm'
import NorthstarLienForm from '@/components/PatientIntake/NorthstarLienForm'
import PolarisLienForm from '@/components/PatientIntake/PolarisLienForm'
import TreatmentReferralForm from '@/components/PatientIntake/TreatmentReferralForm'
import ReviewForm from '@/components/PatientIntake/ReviewForm'
import event, { Events } from '@/event'

export default {
  name: 'PatientIntakeForm',
  mixins: [AddressMixin, DateMixin, RulesMixin],
  components: {
    PatientForm,
    AccidentForm,
    MedicationsForm,
    MedicalReleaseForm,
    PrivacyPracticesForm,
    PtsdScreeningForm,
    AssignmentOfBenefitsForm,
    NorthstarLienForm,
    PolarisLienForm,
    TreatmentReferralForm,
    ReviewForm
  },
  props: {
    internal: {
      type: Boolean,
      required: false,
      default: false
    },
    intakeStatus: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      step: this.$store.state.PatientIntake.step,
      IntakeSteps,
      steps: [
        {
          text: IntakeSteps.PATIENT.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.ACCIDENT.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.MEDICATIONS.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.PTSD.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.RELEASE.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.PRIVACY.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.BENEFITS.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.NORTHSTAR_LIEN.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.POLARIS_LIEN.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.REFERRAL.form,
          editable: true,
          show: () => true
        },
        {
          text: IntakeSteps.REVIEW.form,
          editable: true,
          show: () => true
        },
        {
          text: 'Success',
          editable: false,
          show: () => this.showSuccess
        }
      ],
      showError: false,
      showSuccess: false,
      successForm: true,
      userContext: 'patient',
      invalidForms: []
    }
  },
  computed: {
    ...mapState('PatientIntake', [
      'patientInfo',
      'accidentInfo',
      'medications',
      'ptsdInfo',
      'medicalReleaseInfo',
      'privacyPracticesInfo',
      'benefitsInfo',
      'northstarLienInfo',
      'polarisLienInfo',
      'treatmentReferralInfo',
      'reviewInfo'
    ]),
    locationAddresses () {
      // TODO - needed on patient released from on medical release ?
      return this.locations.map(l => l.address)
    },
    visibleSteps () {
      return this.steps.filter(step => step.show())
    },
    ...mapGetters({
      inviteToken: 'User/getInviteToken',
      url: 'Organization/apiUrl'
    })
  },
  mounted () {
    if (this.step === 12) {
      this.showSuccess = true
    }
    this.getSelf()
  },
  methods: {
    ...mapActions('PatientIntake', ['setStep', 'setAllInfo', 'setPopulateInfo', 'setResetSignatures']),
    ...mapActions('User', ['getSelf']),
    stepVisible (stepLabel) {
      const step = this.steps.find(s => s.text === stepLabel)
      if (step) {
        return step.show()
      }

      return false
    },
    stepValue (stepLabel) {
      const index = this.steps.findIndex(s => s.text === stepLabel)
      return index + 1
    },
    // TODO: will patients OR attorneys be able to fill out forms ?
    handleUserContextSelect (type) {
      // this.userContext = type
      // this.step = 2
    },
    back () {
      this.step--
      this.setStep(this.step)
    },
    nextStep (stepLabel) {
      this.step = this.stepValue(stepLabel) + 1
      this.setStep(this.step)
    },
    formatState () {
      return {
        step: this.step,
        patientInfo: this.patientInfo,
        accidentInfo: this.accidentInfo,
        medications: this.medications,
        ptsdInfo: this.ptsdInfo,
        medicalReleaseInfo: this.medicalReleaseInfo,
        privacyPracticesInfo: this.privacyPracticesInfo,
        benefitsInfo: this.benefitsInfo,
        northstarLienInfo: this.northstarLienInfo,
        polarisLienInfo: this.polarisLienInfo,
        treatmentReferralInfo: this.treatmentReferralInfo,
        reviewInfo: this.reviewInfo
      }
    },
    updateState () {
      this.setStep(this.step)
      const payload = this.formatState()
      this.setAllInfo(payload)
      this.setPopulateInfo()
      this.checkAllSignatures()
    },
    async saveAndExit () {
      this.updateState()
      const payload = this.formatState()
      await window.axios.put(`/v1/individual/intakes/${this.$store.state.User.patient.uuid}`, payload)
      await this.getSelf()
      await this.logout()
    },
    logout () {
      this.$store
        .dispatch('User/logout')
        .then(() => {
          this.$router.push('/signup/individual/verify?token=' + this.inviteToken)
        })
    },
    // patient submitting form
    async submitIntakeForm () {
      this.updateState()
      const payload = this.formatState()
      try {
        const response = await window.axios.put(`/v1/individual/intakes/${this.$store.state.User.patient.uuid}/submit`, payload)
        if (response.status === 200) {
          event.emit(Events.LOADING, true, 'Please wait while your Patient Intake Form is being submitted')
          await window.axios.post(`/v1/individual/intakes/${this.$store.state.User.patient.uuid}/agreements`)
          this.step = 12
          this.showSuccess = true
        }
      } catch (e) {
        console.log(e)
      }
      await this.getSelf()
      this.$refs.reviewForm.loading = false
      event.emit(Events.LOADING, false)
    },
    setStepClick (stepLabel) {
      this.setStep(this.stepValue(stepLabel))
      this.updateState()
    },
    checkAllSignatures () {
      const mainSignature = this.patientInfo.patient.signature
      const signatures = [
        { label: 'ptsdInfo', signature: this.ptsdInfo.signature.signature },
        { label: 'medicalReleaseInfo', signature: this.medicalReleaseInfo.signature.signature },
        { label: 'privacyPracticesInfo', signature: this.privacyPracticesInfo.signature.signature },
        { label: 'benefitsInfo', signature: this.benefitsInfo.signature.signature },
        { label: 'northstarLienInfo', signature: this.northstarLienInfo.signature.signature },
        { label: 'polarisLienInfo', signature: this.polarisLienInfo.signature.signature },
        { label: 'treatmentReferralInfo', signature: this.treatmentReferralInfo.signature.signature },
        { label: 'reviewInfo', signature: this.reviewInfo.signature.signature }
      ]
      signatures.forEach(signature => {
        if (signature.signature.toLowerCase().trim() !== mainSignature.toLowerCase()) {
          this.setResetSignatures(signature.label)
        }
      })
    }
  },
  beforeDestroy () {
    this.$store.dispatch('PatientIntake/setResetState')
  }
}
</script>

<style>
.v-stepper__wrapper {
  overflow: unset !important;
  transition: none;
}
.v-stepper__header {
  padding: 0 24px;
  flex-wrap: nowrap;
}

.v-stepper__step {
  padding: 8px 0 !important;
}

</style>
