<template>
  <div class="d-flex justify-center align-center metric_value">
    <span class="prefix">
      {{ prefix }}
    </span>
    <span class="mx-2">{{ displayValue.toLocaleString() }}</span>
    <span class="suffix">
      {{ suffix }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Number,
      required: true
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    suffix: {
      type: String,
      required: false,
      default: ''
    },
    animate: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      }
    },
    animateStep: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data: () => ({
    displayValue: 0
  }),
  created () {
    if (this.animate) {
      this.doAnimate()
    } else {
      this.displayValue = this.data
    }
  },
  methods: {
    doAnimate () {
      if (this.displayValue < this.data) {
        this.displayValue += this.animateStep
        setTimeout(this.doAnimate, 5)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.metric_value {
  height: 100%;
  text-align: center;
  width: 100%;
  font-size: 4rem;
  font-weight: bold;
  color: #212121;
  padding-bottom: 1.5rem;
}
.suffix {
  font-size: 2rem;
}
</style>
