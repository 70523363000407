<template>
  <v-form ref="patientForm" v-model="patientForm" :disabled="disableForm">
    <h2 class="mb-2">Personal Information</h2>
    <div>
      <v-row dense>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.first_name"
                        label="* First Name"
                        :rules="[rules.required]"
                        filled dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.middle_name"
                        label="Middle Name"
                        filled dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.last_name"
                        label="* Last Name"
                        :rules="[rules.required]"
                        filled dense></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.suffix"
                        label="Suffix"
                        filled dense></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" md="3">
          <DatePicker
            ref="datepicker"
            :date="patientInfo.patient.dob"
            :default-to-today="false"
            label="* Date of Birth"
            @date-change="setDob"
            :dateValid="dobValid"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.ssn"
                        type="email"
                        label="SSN"
                        :rules="[rules.optionalSSN]"
                        v-mask="'###-##-####'"
                        filled dense />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="patientInfo.patient.email"
                        type="email"
                        label="Email"
                        :rules="[rules.optionalEmail]"
                        filled dense />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.main_phone"
                        type="text"
                        label="* Main Phone"
                        :rules="[rules.required, rules.phone]"
                        filled dense
                        v-mask="'(###) ###-####'"/>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.secondary_phone"
                        type="text"
                        label="Secondary Phone"
                        filled dense
                        :rules="[rules.optionalPhone]"
                        v-mask="'(###) ###-####'"/>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select v-model="patientInfo.patient.gender"
                    label="* Gender"
                    :items="['Male', 'Female', 'Other']"
                    filled dense
                    :rules="[rules.required]"
                    @input="checkGenderOther"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="patientInfo.patient.gender_other"
                        type="text"
                        label="Gender Other"
                        filled dense
                        :disabled="patientInfo.patient.gender !== 'Other'"
                        :rules="[
                          (patientInfo.patient.gender !== 'Other' || (patientInfo.patient.gender === 'Other' && !!patientInfo.patient.gender_other)) || 'Required'
                        ]"
          />
        </v-col>
      </v-row>
    </div>

    <h2 class="mt-8 mb-2">Home Address</h2>
    <v-row dense>
      <v-col cols="12" md="8">
        <v-text-field v-model="patientInfo.patient.address.address_line1"
                      label="* Street Address"
                      filled dense
                      :rules="[rules.required]"/>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="patientInfo.patient.address.address_line2"
                      label="Address Line 2"
                      filled dense />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="patientInfo.patient.address.city"
                      label="* City"
                      filled dense
                      :rules="[rules.required]"/>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <StatePicker :state="patientInfo.patient.address.state" :rules="[rules.required]" @state-change="setState"/>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="patientInfo.patient.address.zip"
                      label="* Zip Code"
                      filled dense
                      :rules="[rules.zip, rules.required]"/>
      </v-col>
    </v-row>

    <h2 class="mt-8 mb-2">Emergency Contact</h2>
    <v-row dense>
      <v-col cols="12" sm="6" md="3">
        <v-text-field v-model="patientInfo.emergencyContact.name" label="* Full Name" filled dense :rules="[rules.required]"/>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field v-model="patientInfo.emergencyContact.relationship" label="* Relationship" filled dense :rules="[rules.required]"/>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          v-model="patientInfo.emergencyContact.main_phone"
          label="* Main Phone"
          :rules="[rules.required, rules.phone]"
          filled dense
          v-mask="'(###) ###-####'"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          v-model="patientInfo.emergencyContact.secondary_phone"
          label="Secondary Phone"
          :rules="[rules.optionalPhone]"
          filled dense
          v-mask="'(###) ###-####'"
        />
      </v-col>
    </v-row>

    <h2 class="mt-8 mb-2">Primary Care Physician</h2>
    <v-checkbox label="I have a Primary Care Physician" v-model="patientInfo.havePhysician" class="my-1" @change="checkPhysician"/>
    <v-row dense v-if="patientInfo.havePhysician">
      <v-col cols="12" sm="6" md="8">
        <v-text-field v-model="patientInfo.primaryPhysician.name" label="* Primary Care Physician" filled dense :rules="[rules.required]"/>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="patientInfo.primaryPhysician.main_phone" label="* Phone" filled dense v-mask="'(###) ###-####'" :rules="[rules.required, rules.phone]"/>
      </v-col>
    </v-row>

    <h2 class="mt-8 mb-2">Legal</h2>
    <div class="font-italic">Please check all that apply</div>
    <div v-for="option in PatientLegalOptions" :key="option.value">
      <v-checkbox :label="option.text" :value="option.value" hide-details v-model="patientInfo.legal" @change="checkAttorney"/>
    </div>

    <div class="keepHeight mt-3">
      <v-row dense class="ma-0" v-if="patientInfo.legal.includes(PatientLegal.HIRED_ATTORNEY)">
        <v-col cols="8" class="pl-0 mr-1">
          <v-text-field
            v-model="patientInfo.attorney.organization_name"
            label="* Name of Firm"
            filled dense
            class="fieldHeight"
            :rules="[patientInfo.legal.includes(PatientLegal.HIRED_ATTORNEY) ? rules.required : '']"
          />
        </v-col>
        <v-col class="pl-0">
          <v-text-field
            v-model="patientInfo.attorney.organization_main_phone"
            label="* Phone"
            filled dense
            class="fieldHeight"
            v-mask="'(###) ###-####'"
            :rules="patientInfo.legal.includes(PatientLegal.HIRED_ATTORNEY) ? [rules.required, rules.phone] : ''"
          />
        </v-col>
      </v-row>
    </div>

    <h2 class="mt-8 mb-2">Signature</h2>
    <div class="font-italic mb-2">Any changes to this signature will result in having to resign all agreements</div>
    <v-text-field
      v-model="patientInfo.patient.signature"
      label="* Signature"
      hint="First and Last name"
      filled dense
      :rules="[rules.required, signatureMatchesName || 'Signature does not match name listed above']"
    />

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      :disableBack="true"
      @nextStep="validatePatientForm"
    />
  </v-form>
</template>
<script>
import AddressMixin from '../../mixins/AddressMixin'
import DateMixin from '../../mixins/DateMixin'
import RulesMixin from '../../mixins/RulesMixin'
import PatientLegalOptions from '@/js/PatientIntake/PatientLegalOptions'
import { PatientLegal } from '@/js/PatientIntake/PatientLegal'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapState, mapActions } from 'vuex'
import DatePicker from '@/components/PatientIntake/DatePicker'
import StatePicker from '@/components/FormFields/StatePicker'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'PatientForm',
  mixins: [AddressMixin, DateMixin, RulesMixin],
  components: { FormNavButtons, DatePicker, StatePicker },
  props: {
    disableForm: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      IntakeSteps,
      patientForm: true,
      PatientLegalOptions,
      PatientLegal,
      dobValid: true
    }
  },
  computed: {
    ...mapState('PatientIntake', ['patientInfo']),
    signatureMatchesName () {
      return this.patientInfo.patient.signature.toLowerCase().trim() === this.fullName
    },
    fullName () {
      return this.patientInfo.patient.first_name.trim().toLowerCase() + ' ' + this.patientInfo.patient.last_name.trim().toLowerCase()
    }
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo', 'setPopulateInfo']),
    validatePatientForm () {
      this.$refs.patientForm.validate()
      this.validateDob()
      if (this.patientForm && this.dobValid !== 'Required' && !!this.patientInfo.patient.signature) {
        this.formatSignature()
        this.setInfo({ label: IntakeSteps.PATIENT.label, data: this.patientInfo })
        this.setPopulateInfo()
        this.$emit('nextStep', IntakeSteps.PATIENT.form)
      }
    },
    navigate (command) {
      this.formatSignature()
      this.setInfo({ label: IntakeSteps.PATIENT.label, data: this.patientInfo })
      this.setPopulateInfo()
      this.$emit(command)
    },
    checkGenderOther () {
      if (this.patientInfo.patient.gender === 'Other') {
        return
      }
      this.patientInfo.patient.gender_other = ''
    },
    checkAttorney () {
      if (this.patientInfo.legal.includes(PatientLegal.HIRED_ATTORNEY)) {
        return
      }
      this.patientInfo.attorney.main_phone = ''
      this.patientInfo.attorney.organization_name = ''
    },
    setDob (value) {
      this.patientInfo.patient.dob = value
    },
    validateDob () {
      this.dobValid = true
      if (!this.patientInfo.patient.dob) {
        this.dobValid = 'Required'
      }
    },
    setState (value) {
      this.patientInfo.patient.address.state = value
    },
    checkPhysician () {
      if (this.patientInfo.havePhysician) {
        return
      }
      this.patientInfo.primaryPhysician.name = ''
      this.patientInfo.primaryPhysician.main_phone = ''
    },
    formatSignature () {
      this.patientInfo.patient.signature = this.patientInfo.patient.signature.trim()
    }
  }
}
</script>

<style>

</style>
