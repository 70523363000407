export default function (to, from, next) {
  // check that a token query parameter is set
  const token = to.query.token
  if (!token) {
    return next(new Error('This page requires a unique token. Verify the link contains a token and try again. Please contact support if you continue to see this error.'))
  }

  // get the payload portion of the jwt
  let payload = token.split('.')[1]
  // decode from base64 into json object
  payload = JSON.parse(atob(payload))

  const now = Math.round(new Date().valueOf() / 1000)
  if (!payload.exp || payload.exp < now) {
    return next(new Error('The token provided has expired.'))
  }

  next()
}
