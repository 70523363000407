import store from '../../store/index'
export default function (to, from, next) {
  // collapse roles from all matched routes into single roles array
  const roles = to.matched.reduce((roles, route) => {
    if (route.meta.roles) {
      roles = [...roles, ...route.meta.roles]
    }
    return roles
  }, [])

  if (roles.length > 0 && !store.getters['User/hasAccess'](roles)) {
    next({ replace: true, name: 'Unauthorized' })
  }

  next()
}
