<template>
  <chart :id="id" chart-type="line" :data="data" :options="options" />
</template>
<script>
import Chart from '../Chart'
export default {
  components: { Chart },
  props: {
    id: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: {
        elements: {
          line: {
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            borderColor: this.$vuetify.theme.currentTheme.primary
          }
        }
      }
    }
  },
  methods: {}
}
</script>
