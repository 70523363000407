<template>
  <v-row dense class="mt-4">
    <v-col>
      <h2 class="mb-2">Signature</h2>
      <div v-if="!certified && getSignature" class="red--text">Please select the acknowledgement checkbox(s) in order to sign</div>
      <div v-if="!getSignature" class="red--text">Please enter a signature in the Patient Form to apply a signature</div>
      <div v-if="certified && getSignature">
        <v-checkbox
          v-model="applySignature"
          v-if="getSignature && !signatureObject.signature"
          label="Apply Signature"
          @change="setSignature(signatureObject)"
        />
        <div v-if="signatureObject.signature" class="font-weight-bold">
          <span>SIGNATURE APPLIED: {{signatureObject.signature}}, {{signatureObject.date}}</span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>

import RulesMixin from '../../mixins/RulesMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'SignatureBlock',
  components: {},
  mixins: [RulesMixin],
  props: ['value', 'certified'],
  data () {
    return {
      applySignature: false
    }
  },
  computed: {
    ...mapGetters('PatientIntake', ['getSignature']),
    signatureObject: {
      get () {
        return this.value
      },
      set (changedValue) {
        this.$emit('input', changedValue)
      }
    }
  },
  methods: {
    ...mapGetters('PatientIntake', ['fullName']),
    setSignature (signatureItem) {
      if (!this.applySignature) {
        signatureItem.signature = ''
        signatureItem.date = ''
        return
      }
      signatureItem.signature = this.getSignature
      signatureItem.date = new Date().toLocaleString('en-US')
      this.applySignature = false
    },
    resetApplySignature () {
      this.applySignature = false
    }
  }
}
</script>

<style>
.save-btn {
  background-color: #386C03 !important;
  color: #fff !important;
}

</style>
