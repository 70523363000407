<template>
  <v-form ref="assignmentOfBenefitsForm" v-model="assignmentOfBenefitsForm">
    <h2 class="mb-2">Assignment of Benefits</h2>
    <div>
      <v-checkbox
        label="I authorize the liable insurance company to directly compensate Northstar Healthcare for any medical services received at their facility or
              facilities that they hold the liens for my care. "
        v-model="benefitsInfo.certify_compensate_northstar"
        :rules="[rules.required]"
        :disabled="benefitsInfo.certify_send_check && benefitsInfo.certify_compensate_northstar && !!benefitsInfo.signature.signature"
      />
      <v-checkbox
        label="I authorize you to send the check directly to 1839 Madison Ave, Suite 101 Memphis, TN 38104"
        v-model="benefitsInfo.certify_send_check"
        :rules="[rules.required]"
        :disabled="benefitsInfo.certify_send_check && benefitsInfo.certify_compensate_northstar && !!benefitsInfo.signature.signature"
      />
    </div>

    <div class="font-weight-bold mt-8">PATIENT NAME: {{fullName()}}</div>

    <SignatureBlock v-model="benefitsInfo.signature" :certified="benefitsInfo.certify_send_check && benefitsInfo.certify_compensate_northstar"/>

    <FormNavButtons
      @saveAndExit="navigate('saveAndExit')"
      @back="navigate('back')"
      @nextStep="validateAssignmentOfBenefitsForm"
    />
  </v-form>
</template>
<script>
import RulesMixin from '../../mixins/RulesMixin'
import FormNavButtons from '@/components/PatientIntake/FormNavButtons'
import { mapState, mapActions, mapGetters } from 'vuex'
import SignatureBlock from '@/components/PatientIntake/SignatureBlock'
import IntakeSteps from '@/js/PatientIntake/IntakeSteps'

export default {
  name: 'AssignmentOfBenefitsForm',
  mixins: [RulesMixin],
  components: { FormNavButtons, SignatureBlock },
  data () {
    return {
      IntakeSteps,
      assignmentOfBenefitsForm: true
    }
  },
  computed: {
    ...mapState('PatientIntake', ['benefitsInfo'])
  },
  methods: {
    ...mapActions('PatientIntake', ['setInfo']),
    ...mapGetters('PatientIntake', ['fullName']),
    validateAssignmentOfBenefitsForm () {
      this.$refs.assignmentOfBenefitsForm.validate()
      if (this.assignmentOfBenefitsForm && this.benefitsInfo.signature.signature) {
        this.setInfo({ label: IntakeSteps.BENEFITS.label, data: this.benefitsInfo })
        this.$emit('nextStep', IntakeSteps.BENEFITS.form)
      }
    },
    navigate (command) {
      this.setInfo({ label: IntakeSteps.BENEFITS.label, data: this.benefitsInfo })
      this.$emit(command)
    }
  }
}
</script>

<style>

</style>
